import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { Get_User_Data,Update_User,upload_File} from "./GraphQL";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import {setProfileUserActions} from "../store/profileActions"
import { GlobalHooks } from "../../../../utils/Hooks";

export const GraphQLFetchData = () => {
  const {setCookie} = GlobalHooks()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const removeTypename = (data: any): any => {
    if (Array.isArray(data)) {
      return data.map(removeTypename);
    } else if (typeof data === 'object' && data !== null) {
      const newData: any = { ...data };
      delete newData.__typename;
      Object.keys(newData).forEach((key) => {
        newData[key] = removeTypename(newData[key]);
      });
      return newData;
    }
    return data;
  }
  

const [uploadFile] = useMutation(
    upload_File,
  {
    fetchPolicy:  "no-cache",
    onCompleted:(data)=>{
      if(data?.UploadToS3?.code === 200){
        dispatch(setProfileUserActions({profileConstraintsDataNameChange:"Vignette",profileConstraintsDataValueChange:data.UploadToS3?.UrlFile}))
        setCookie('thumbnail',  data.UploadToS3?.UrlFile,{ path: '/' });

      }
    }
  }
);
const [GetUserData] = useMutation(
  Get_User_Data,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
          dispatch(
            ({ type: "SET_ProfileData", payload: removeTypename(data.User)})
          );
          dispatch(setProfileUserActions({
            profileConstraintsDataNameChange: "Id",
            profileConstraintsDataValueChange: data.User.Id
        }));
        dispatch(setProfileUserActions({
          profileConstraintsDataNameChange:"Email",
          profileConstraintsDataValueChange: data.User.Email
      }));
      dispatch(setProfileUserActions({
        profileConstraintsDataNameChange:"Adresse",
        profileConstraintsDataValueChange: data.User.Adresse
      }));
      dispatch(setProfileUserActions({
        profileConstraintsDataNameChange:"Phone",
        profileConstraintsDataValueChange: data.User.Phone
      }));
      dispatch(setProfileUserActions({
        profileConstraintsDataNameChange:"LastName",
        profileConstraintsDataValueChange: data.User.LastName
      }));
      dispatch(setProfileUserActions({
        profileConstraintsDataNameChange:"FirstName",
        profileConstraintsDataValueChange: data.User.FirstName
    }));
    dispatch(setProfileUserActions({
      profileConstraintsDataNameChange:"City",
      profileConstraintsDataValueChange: data.User.City
    }));
    dispatch(setProfileUserActions({
      profileConstraintsDataNameChange:"Vignette",
      profileConstraintsDataValueChange: data.User.Vignette
    }));
    dispatch(setProfileUserActions({
      profileConstraintsDataNameChange:"Society",
      profileConstraintsDataValueChange: data.User.Society
    }));
   
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"PostalCode",
  profileConstraintsDataValueChange: data.User.PostalCode
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"NumberPerson",
  profileConstraintsDataValueChange: data.User.NumberPerson
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"Username",
  profileConstraintsDataValueChange: data.User.Username
}));

    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);
const [UpdateUser] = useMutation(
  Update_User,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
          dispatch(
            ({ type: "SET_ProfileData", payload: data.updateUser})
          );
          dispatch(setProfileUserActions({
            profileConstraintsDataNameChange: "Id",
            profileConstraintsDataValueChange: data.updateUser.Id
        }));
        dispatch(setProfileUserActions({
          profileConstraintsDataNameChange:"Email",
          profileConstraintsDataValueChange: data.updateUser.Email
      }));
      dispatch(setProfileUserActions({
        profileConstraintsDataNameChange:"FirstName",
        profileConstraintsDataValueChange: data.updateUser.FirstName
    }));
    dispatch(setProfileUserActions({
      profileConstraintsDataNameChange:"LastName",
      profileConstraintsDataValueChange: data.updateUser.LastName
  }));
  dispatch(setProfileUserActions({
    profileConstraintsDataNameChange:"Vignette",
    profileConstraintsDataValueChange: data.updateUser.Vignette
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"Phone",
  profileConstraintsDataValueChange: data.updateUser.Phone
}));

dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"Adresse",
  profileConstraintsDataValueChange: data.updateUser.Adresse
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"City",
  profileConstraintsDataValueChange: data.updateUser.City
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"Society",
  profileConstraintsDataValueChange: data.updateUser.Society
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"PostalCode",
  profileConstraintsDataValueChange: data.updateUser.PostalCode
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"NumberPerson",
  profileConstraintsDataValueChange: data.updateUser.NumberPerson
}));
dispatch(setProfileUserActions({
  profileConstraintsDataNameChange:"Username",
  profileConstraintsDataValueChange: data.updateUser.Username
}));

    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);
const [UpdateUserVignette] = useMutation(
  Update_User,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
          dispatch(
            ({ type: "SET_ProfileData", payload: data.updateUser})
          );
       
  dispatch(setProfileUserActions({
    profileConstraintsDataNameChange:"Vignette",
    profileConstraintsDataValueChange: data.updateUser.Vignette
}));

    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);
  
  return {
    GetUserData,
    UpdateUser,
    uploadFile,
    UpdateUserVignette

  };
}