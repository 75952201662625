import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import Cookies from 'js-cookie'
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import VideocamIcon from '@mui/icons-material/Videocam';
const ThemeBookGuide = () => {
  const {t} = useTranslation()
  const divStyle = {}
  const iconsStyle = {display:"inline-flex",alignItems:"center",justifyContent:"center",marginInline:"1rem",borderRadius:"4px",height:"2rem",width:"100px",color:Cookies.get('ColorTheme') === "default" ? "#F8FBFF" : Cookies.get('ColorTheme') === "c2" ? "#cee3e7" : Cookies.get('ColorTheme') === "c3" ? "#426c7a" : Cookies.get('ColorTheme') === "c4" ? "#8499e4" : Cookies.get('ColorTheme') === "c5" ? "#11776c" : "#aaaaaa" ,backgroundColor : Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"}
  return (
    <>
    <div style={divStyle}>{parse(t("Books.add_button_Theme_help"))}<span style={iconsStyle}><AddIcon fontSize='1rem'/>{t("add")}</span></div> 
    <hr style={{transform:"scaleY(0.5)"}} />
    <div style={divStyle}>{parse(t("Books.arrow_button_Theme_help1"))}<span style={{...iconsStyle,width:"40px"}}><ArrowForwardIosIcon fontSize='1rem'/></span>{parse(t("Books.arrow_button_Theme_help2"))}<span style={{...iconsStyle,width:"40px"}}><ArrowBackIosNewIcon fontSize='1rem'/></span></div>
    <hr style={{transform:"scaleY(0.5)"}} />
    <div style={divStyle}>{parse(t("Books.search_area_Theme_help"))}</div>
    <hr style={{transform:"scaleY(0.5)"}} />
    <div style={divStyle}>{parse(t("Books.tri_column_Theme_help1"))}<span style={{...iconsStyle,width:"40px"}}><UnfoldMoreIcon fontSize='1rem'/></span>{parse(t("Books.tri_column_Theme_help2"))}</div>
    <hr style={{transform:"scaleY(0.5)"}} />
    <div style={divStyle}>{parse(t("Books.edit_button_Theme_help1"))}<span style={{...iconsStyle,width:"40px"}}><EditIcon fontSize='1rem'/></span>{parse(t("Books.edit_button_Theme_help2"))}</div>
    <hr style={{transform:"scaleY(0.5)"}} />
    <div style={divStyle}>{parse(t("Books.detail_button_Theme_help1"))}<span style={{...iconsStyle,width:"40px"}}><RemoveRedEyeIcon fontSize='1rem'/></span>{parse(t("Books.detail_button_Theme_help2"))}</div>
    <hr style={{transform:"scaleY(0.5)"}} />
    <div style={divStyle}>{parse(t("Books.delete_button_Theme_help1"))}<span style={{...iconsStyle,width:"40px"}}><DeleteIcon fontSize='1rem'/></span>{parse(t("Books.delete_button_Theme_help2"))}</div>
    <hr style={{transform:"scaleY(0.5)"}} />
    <div style={divStyle}>{t("Books.To see the theme's export links, you must click the button")}<span style={{...iconsStyle,width:"40px"}}><VideocamIcon fontSize='1rem'/></span>{t("Books.in the same line (in the Actions column)")}</div>
    </>
  )
}

export default ThemeBookGuide