import React from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import filterBar from "../assets/filter-bar.jpg"
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';

import TheatersIcon from '@mui/icons-material/Theaters';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import QuizIcon from '@mui/icons-material/Quiz';
import DownloadIcon from '@mui/icons-material/Download';



import DeleteIcon from '@mui/icons-material/Delete';

import PublicIcon from '@mui/icons-material/Public';
import HomeIcon from '@mui/icons-material/Home';

import youtube from "../assets/youtube.png"
import daily from "../assets/dailymotion.png"
import email from "../assets/mail.png"
import facebook from "../assets/fb.png"
import twitter from "../assets/twitter.png"
import linkedin from "../assets/in.png"
import paginationFooter from "../assets/paginationVideo.png"
const VideoBookGuide = () => {
    const {t} = useTranslation()
    const divStyle = {}
    const iconsStyle = {display:"inline-flex",alignItems:"center",justifyContent:"center",marginInline:"1rem",borderRadius:"4px",height:"2rem",width:"100px",color:Cookies.get('ColorTheme') === "default" ? "#F8FBFF" : Cookies.get('ColorTheme') === "c2" ? "#cee3e7" : Cookies.get('ColorTheme') === "c3" ? "#426c7a" : Cookies.get('ColorTheme') === "c4" ? "#8499e4" : Cookies.get('ColorTheme') === "c5" ? "#11776c" : "#aaaaaa" ,backgroundColor : Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"}
    const color = Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"
    return (
    <>
        <div style={divStyle}>{t("Books.ADD VIDEO")}</div>
        <div style={divStyle}>{t("Books.To do this, click the button at the top right")}<span style={iconsStyle}><FileUploadIcon fontSize='1rem'/>{t("add")}</span></div> 
        <hr style={{transform:"scaleY(0.5)"}} />
        <div style={divStyle}>{t("Books.SEARCH TOOLS")}</div>
        <div style={divStyle}>{t("Books.UseSearches")}</div> 
        <div>
            <img style={{maxWidth:"100%"}} src={filterBar} alt="" />
        </div>
        <hr style={{transform:"scaleY(0.5)"}} />
        <div style={divStyle}>{t("Books.DIFFUSION")}<span style={iconsStyle}><VideocamIcon fontSize='1rem'/><ArrowDropDownIcon fontSize='1rem'/></span></div>
        <div style={divStyle}>{t("Books.In the top left, the DIFFUSION menu allows you to view videos by type")}</div> 
        <div style={divStyle}>{t("Books.-On going (visible videos for web users)")}</div>
        <div style={divStyle}>{t("Books.-Archived (videos that have been released)")}</div> 
        <div style={divStyle}>{t("Books.-Upcoming (videos scheduled for next broadcast)")}</div>
        <div style={divStyle}>{t("Books.-Offline (manually disabled videos)")}</div>
        <hr style={{transform:"scaleY(0.5)"}} />
        <div style={divStyle}>{t("Books.DISPLAY")}<span style={iconsStyle}><DashboardIcon fontSize='1rem'/><ArrowDropDownIcon fontSize='1rem'/></span></div>
        <div style={divStyle}>{t("Books.In the top left, the DISPLAY menu allows viewing videos in 5 modes")}</div>
        <div style={divStyle}>{t("Books.Medium List, Large List, Thumbnails, Small List, Directories")}</div>
        <hr style={{transform:"scaleY(0.5)"}} />
        <div style={divStyle}>{t("Books.LISTS SORT")}</div>
        <div style={divStyle}>{t("Books.Click on the column Id or Titre to sort data")}</div>
        <hr style={{transform:"scaleY(0.5)"}} />
        <div style={divStyle}>{t("Books.ACTIONS")}</div>
        <div style={divStyle}>{t("Books.List of available functions (according to your rights and your profile)")}</div>
        <div className='ActionsBooksMediaVideo'>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><EditIcon fontSize='1rem'/><ArrowDropDownIcon fontSize='1rem'/></span>{" "}{t("Books.Edit a video")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><ContentCutIcon fontSize='1rem'/></span>{" "}{t("Books.Editing the video")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><DownloadIcon fontSize='1rem'/></span>{" "}{t("Books.Download file")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Rich Media")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><TheatersIcon fontSize='1rem'/></span>{" "}{t("Books.Change the source video")}</div>
            <div className="oneActionBook"><img src={email} alt="" />{" "}{t("Books.Send video by email")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><PeopleIcon fontSize='1rem'/></span>{" "}{t("Books.Subscriber management")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><QuizIcon fontSize='1rem'/></span>{" "}{t("Books.View questions/quiz")}</div>
            <div className="oneActionBook"><img src={twitter} alt="" />{" "}{t("Books.Export Twitter")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Meta-data of a video")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Highlight (home page)")}</div>
            <div className="oneActionBook"><img src={linkedin} alt="" />{" "}{t("Books.Export Linkedin")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><DeleteIcon fontSize='1rem'/></span>{" "}{t("Books.Delete a video")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Audio description")}</div>
            <div className="oneActionBook"><img src={facebook} alt="" />{" "}{t("Books.Export Facebook")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><HomeIcon fontSize='1rem'/></span>{" "}{t("Books.Add video to home videos")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Chronology of the video")}</div>
            <div className="oneActionBook"><img src={youtube} alt="" />{" "}{t("Books.YouTube export")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><BarChartIcon fontSize='1rem'/></span>{" "}{t("Books.Video statistics")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><ClosedCaptionIcon fontSize='1rem'/></span>{" "}{t("Books.Captions")}</div>
            <div className="oneActionBook"><img src={daily} alt="" />{" "}{t("Books.DailyMotion Export")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><PublicIcon fontSize='1rem'/></span>{" "}{t("Books.Go online / offline")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Links and URL of the video")}</div>
        </div>
        <hr style={{transform:"scaleY(0.5)"}} />
        <div style={divStyle}>{t("Books.NAVIGATION")}</div>
        <div style={divStyle}>{t("Books.At the bottom, functions are used to adjust the display and access to the pages")}</div>
        <div>
            <img style={{maxWidth:"100%"}} src={paginationFooter} alt="" />
        </div>
    </>
  )
}

export default VideoBookGuide