export const DamConstantsData = ()=>{

    const DamConstraintsData :() => { loading: boolean } = () => {
        return (
            {
                loading:false,
            }
        )
    }

    return({
        DamConstraintsData,
    })

}