const ServeurIntegrated = ({language , token}) => {
    const newServeurIframe = "https:" +
    window.process.env.URL_Serveur
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_serveur_display"
        src={newServeurIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default ServeurIntegrated