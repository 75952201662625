import React from "react";
import { useHistory } from "react-router-dom";
import { Hooks } from "../utils/hooks";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Typography,
  Checkbox,
  InputAdornment,
  Link
} from "@mui/material";
import { AccountCircle } from "@material-ui/icons";

export const ForgetPassword = ({handleSubmit}:any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const requiredFieldRule = [
    { required: true, message: t("forgetPassword.FieldsRequired") },
  ];
  const { forgetPasswordOnChange, values } = Hooks();

  return (
    <Grid container spacing={2} className={"col-connexion-forget-pass"}>
      <Grid item xs={12}>
        <Typography variant="h6" className={"span_connexion"}>
          {t("forgetPassword.ResetPass")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={"span_connexion"}>
          {t("forgetPassword.TellUsEmail")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="email"
              onChange={forgetPasswordOnChange}
              placeholder={t("forgetPassword.YourMailAddress")}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              //   error={!!errors.username}
              //   helperText={errors.username && t("error.requiredField")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              //   loading={values.constraintData.loadingForgetPassword}
              className={"spn_chbx"}
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              {t("forgetPassword.SendResetMail")}
            </Button>
          </Grid>
          <Grid item>
            <Link href="/" variant="body2" className={"spn_chbx"}>
              {t("forgetPassword.LoginRedirect")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
