import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import UpdateIcon from '@mui/icons-material/Update';
import eventImageFunctions from "../assets/filter_bare_event.png"
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VideocamIcon from '@mui/icons-material/Videocam';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const EvenementBookGuide = () => {
  const {t} = useTranslation()
  const divStyle = {}
  const iconsStyle = {display:"inline-flex",alignItems:"center",justifyContent:"center",marginInline:"1rem",borderRadius:"4px",height:"2rem",width:"100px",color:Cookies.get('ColorTheme') === "default" ? "#F8FBFF" : Cookies.get('ColorTheme') === "c2" ? "#cee3e7" : Cookies.get('ColorTheme') === "c3" ? "#426c7a" : Cookies.get('ColorTheme') === "c4" ? "#8499e4" : Cookies.get('ColorTheme') === "c5" ? "#11776c" : "#aaaaaa" ,backgroundColor : Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"}
  return (
    <>
      <div style={divStyle}>{t("Books.add_button_Event_help")}<span style={iconsStyle}><AddIcon fontSize='1rem'/>{t("Books.add")}</span></div> 
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.In the top left the button")}<span style={{...iconsStyle,width:"40px",marginTop:"5px"}}><EventIcon fontSize='1rem'/></span>{t("Books.allows you to view list of events in a calendrier")}</div> 
      <div style={divStyle}>{t("Books.le bouton")}<span style={{...iconsStyle,width:"40px",marginTop:"5px"}}><PlayArrowIcon fontSize='1rem'/></span>{t("Books.allows you to view list of events on going")}</div> 
      <div style={divStyle}>{t("Books.le bouton")}<span style={{...iconsStyle,width:"40px",marginTop:"5px"}}><UpdateIcon fontSize='1rem'/></span>{t("Books.allows you to view list of events up coming")}</div> 
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.arrow_button_Event_help1")}<span style={{...iconsStyle,width:"40px"}}><ArrowForwardIosIcon fontSize='1rem'/></span>{t("Books.arrow_button_Event_help2")}<span style={{...iconsStyle,width:"40px"}}><ArrowBackIosNewIcon fontSize='1rem'/></span></div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.Use the functions at the top of the screen to perform multiCriteria searches")}</div> 
      <div>
        <img style={{maxWidth:"100%"}} src={eventImageFunctions} alt="" />
      </div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.tri_column_Event_help1")}<span style={{...iconsStyle,width:"40px"}}><UnfoldMoreIcon fontSize='1rem'/></span>{t("Books.tri_column_Event_help2")}</div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.edit_button_Event_help1")}<span style={{...iconsStyle,width:"40px"}}><EditIcon fontSize='1rem'/></span>{t("Books.edit_button_Event_help2")}</div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.detail_button_Event_help1")}<span style={{...iconsStyle,width:"40px"}}><RemoveRedEyeIcon fontSize='1rem'/></span>{t("Books.detail_button_Event_help2")}</div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.delete_button_Event_help1")}<span style={{...iconsStyle,width:"40px"}}><DeleteIcon fontSize='1rem'/></span>{t("Books.delete_button_Event_help2")}</div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.To see the event's export links, you must click the button")}<span style={{...iconsStyle,width:"40px"}}><VideocamIcon fontSize='1rem'/></span>{t("Books.in the same line (in the Actions column)")}</div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.To see the event's statistic, you must click the button")}<span style={{...iconsStyle,width:"40px"}}><BarChartIcon fontSize='1rem'/></span>{t("Books.in the same line (in the Actions column)")}</div>
    </>
  )
}

export default EvenementBookGuide