import {useMutation} from "@apollo/react-hooks";
import {graphQL_shema} from "./graphQL";
import {useDispatch} from "react-redux";
import {
    setForgetPasswordConstraintDataOnchange
} from "../store/forgetPasswordAction";


export const GraphQLFetchData=(values:any)=> {

    const dispatch = useDispatch()

    const [ForgetPassword] = useMutation(graphQL_shema().forgetPassword, {
        variables: {input :values.forgetPassword}
        ,
        onCompleted: async (data) => {
          
            if (data.requestRestePassword.code===200)
                {
                    //history.push("/")
                    //dispatch(setAppSetLogin(data.login.Token));
                    //localStorage.setItem('jwtToken', data.login.Token);
                }
            
            dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"loadingForgetPassword",constraintDataValueChange:false}))
            dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"passwordSent",constraintDataValueChange:true}))
        },
        onError(err) {
            dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"passwordSent",constraintDataValueChange:true}))
            
        }
        
    });
    return({
        ForgetPassword,
    })
}

