
export const UserConstraints = ()=>{


    const UserConstantsData = () => {
        return (
            {
                storageUsername:"",
                storagePassword:"",
                storageIsRememberMe:false,
            }
        )
    }
    return({
        UserConstantsData
    })

}

