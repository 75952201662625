import React, { useState } from 'react';
import { GlobalHooks } from '../../../utils/Hooks';
import Cookies from 'js-cookie';
const HasPermission = ({ permission,allowed,children,childs,per,isAdmin,nav ,redi}) => {
  if (allowed) {
    // if(redi?.length < 1 && !(Cookies.get("redic"))){
    //   if(!nav){
    //     if(!(Cookies.get("redic"))){Cookies.set("redic",childs.indicatorSolutionOnly)}
    //     return true
    //   }
    // }
    return <div has-permission={permission}>{children}</div>;
  }
  else if(!nav){
    if(childs?.MenuItem?.some(u=>(((per?.userData?.modules[u.Permissions]) == true) || (isAdmin) ))){
    // if(redi?.length < 1 && !(Cookies.get("redic"))){
    //   childs.MenuItem.find(u=>{if(((per?.userData?.modules[u.Permissions]) == true) || (isAdmin)){if(!(Cookies.get("redic"))){Cookies.set("redic",u.indicatorSolutionOnly)}} return true })
    // }
     return <div has-permission={permission}>{children}</div>;
   }
  }
  else {
    return <></>
  }
};
export default HasPermission;