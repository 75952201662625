import {combineReducers} from 'redux'
import {reducer} from "./globalStore/reducer";
import {ConnexionReducer} from "../connexion/store/connexionReducer";
import {LayoutReducer} from "../layout/store/layoutReducer";
import {ProfileReducer} from "../layout/components/Profile/store/ProfileReducer";
import {PersistReducer} from "./persistStore/persistReducer";
import { persistReducer } from 'redux-persist';
import {ForgetPasswordReducer} from "../forgetPassword/store/forgetPasswordReducer";
import {ResetPasswordReducer} from "../resetPassword/store/resetPasswordReducer";
import storage from 'redux-persist/lib/storage';


const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['PersistReducer']
};

export const rootReducer = combineReducers({
    reducer,
    ConnexionReducer,
    LayoutReducer,
    ProfileReducer,
    PersistReducer,
    ForgetPasswordReducer,
    ResetPasswordReducer,
})

export default  persistReducer(persistConfig , rootReducer)
