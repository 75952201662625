import {ProfileConstantsData} from "./profileConstants";
import {IActions} from "../../../../schemas/types";

const {ProfileConstraintsData,ProfileConstraintsDataUpdated,ShowButton}=ProfileConstantsData()

const INITIALSTATE = {
    profileConstraintsData:ProfileConstraintsData(),
    ProfileConstraintsDataUpdated:ProfileConstraintsDataUpdated(),
    ShowButton:ShowButton()
}

export const ProfileReducer = (state=INITIALSTATE , action:IActions)=>{

    switch (action.type){

        case "SET_ProfileActions" :
            const {profileConstraintsDataNameChange,profileConstraintsDataValueChange}=action.payload
            return {
                ...state,
                ProfileConstraintsDataUpdated:{...state.ProfileConstraintsDataUpdated, [profileConstraintsDataNameChange]:profileConstraintsDataValueChange}
            }
        case "SET_ProfileData" : 
            const allUserData = action.payload
            return {
                ...state,
                profileConstraintsData: allUserData
            }
        case "SET_ProfileData_Updated" : 
            const allUserDataUpdated = action.payload
            return {
                ...state,
                ProfileConstraintsDataUpdated: allUserDataUpdated
            }
        case "SET_divs_show" : 
            const show = action.payload
            return {
                ...state,
                ShowButton: {"showRegister":show}
            }
        default:{
            return state
        }

    }

}