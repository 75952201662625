import React from "react";
export const LayoutConstantsData = ()=>{

    const LayoutConstraintsData:() => { selectedMenu: string; lang: string ; visible:boolean ; darkMode:boolean} = () => {
        return (
            {
                selectedMenu:"dashboard",
                lang:"fr",
                visible:false,
                darkMode:false
            }
        )
    }

    return({
        LayoutConstraintsData,
    })

}