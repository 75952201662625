import React from 'react';

import './resetPassword.scss'

import {FormResetPassword} from "./components/formResetPassword";
import svg from "../assets/Group.svg";


export const ResetPassword =()=>{
    return(
        <div className={"divGlobalResetPassword"} style={{backgroundImage: `url(${svg})` , backgroundColor:"RGB(240, 243, 247)"}}>
            <FormResetPassword/>
        </div>
    )
}