import { useEffect } from "react";
import { Hooks } from "../layout/utils/hooks";
import { useAppSelector } from "../redux/hooks";
import { GlobalHooks } from "../utils/Hooks";

const DashboardStatsIntegrated = ({language , token}) => {
  const dashboardToken = useAppSelector((state) => state.LayoutReducer.STAT_DASHBOARD_URL)
  const {cookies} = GlobalHooks()
  const {getURLSTATDashboard} = Hooks()

  const OPTIONS = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Authorization" : "Bearer "+ cookies.authToken,
    },
  };

  useEffect(()=>{
    getURLSTATDashboard(OPTIONS)
  },[])

  const newDashboardStatsIframe = "https:" +
    window.process.env.URL_DashboardStats
    +  "?lng=" + language + "&token=" +
    dashboardToken.split("/").at(-1)
  
    return (
      <iframe
        id="iframe_dashboardStats_display"
        src={newDashboardStatsIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default DashboardStatsIntegrated