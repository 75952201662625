import {ConnexionConstraints} from "./connexionConstants";
import {IActions} from "../../schemas/types";
const {connexion , constraintData} = ConnexionConstraints()

const ConnexionInitialState = {

    connexion: connexion(),
    appState: { loggedIn: false },
    authToken : '',
    constraintData:constraintData()

}

export const  ConnexionReducer=(state=ConnexionInitialState , action:any)=>{
    switch (action.type){

        //******** connexion reducer case************//

        case "SET_Permissions":
            return{
                ...state,
                constraintData:{
                    ...state.constraintData,
                    permissions: action.payload
                }
            }
            case "SET_isAdmin":
            return{
                ...state,
                constraintData:{
                    ...state.constraintData,
                    isAdmin: action.payload
                }
            }
        case "SET_ConnexionOnchange":
            const {ConnexionNameChange,ConnexionValueChange}=action.payload
            const ConnexionOnOnchangeObj = {...state.connexion,[ConnexionNameChange]: ConnexionValueChange}
            return{
                ...state,
                connexion:ConnexionOnOnchangeObj
            }
        case "SET_AppSetLogin":
            return {
                ...state,
                appState: { loggedIn: true },
                authToken : action.payload
            }
        case "SET_AppSetLogout":
            return {
                ...state,
                appState: { loggedIn: false },
                authToken : '',
            }
        case "SET_ConnexionConstraintDataOnchange":
            const {constraintDataNameChange,constraintDataValueChange}=action.payload
            const constraintDataOnOnchangeObj = {...state.constraintData,[constraintDataNameChange]: constraintDataValueChange}
            return{
                ...state,
                constraintData:constraintDataOnOnchangeObj
            }
        default:{
            return state
        }
    }

}