import React,{useState} from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./ApiKey.css"
import { GlobalHooks } from '../../../utils/Hooks';
import { useTranslation } from "react-i18next";

export const ApiKey = () => {
  const {cookies} = GlobalHooks()
  const [showMessage, setShowMessage] = useState(false);
  const [messagePosition, setMessagePosition] = useState({ x: 0, y: 0 });
  const {t} = useTranslation()

  const copyText = (event) => {
    navigator.clipboard.writeText(cookies.authToken)
      .then(() => {
        setShowMessage(true);

        const positionX = event.clientX - 400
        const positionY = event.clientY - 50; 

      setMessagePosition({ x: positionX, y: positionY });
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error copying text:', error);
      });
  };

  const handleMouseOver = (event) => {
    const rect = event.target.getBoundingClientRect();
    const positionX = rect.left + rect.width / 2;
    const positionY = rect.top - rect.height;

    setMessagePosition({ x: positionX, y: positionY });
  };

  const colorValue = cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" 

  return (
    <div className='ApiKeySpace'>
        <div className='Blue-Title' style={{ color: colorValue}}>{t("Clé Api")}</div>
        <div onMouseOver={handleMouseOver} className='Layout-content marginBetweenKeyAndToken'>
            <h4 className='tokenText'>{cookies.authToken}</h4>
            <button onClick={(e)=>copyText(e)} className='change-layout-button'  style={{ backgroundColor: colorValue}}>{t("Copier")}</button>
            {showMessage && (
              <div
                className="copy-message"
                style={{ left: messagePosition.x, top: messagePosition.y }}
              >
                <ContentCopyIcon fontSize='inherit'/> {t("Text copied successfully")} 
              </div>
            )}
        </div>
    </div>
  )
}
