import {LayoutConstantsData} from "./layoutConstants";
import {IActions} from "../../schemas/types";

const {LayoutConstraintsData}=LayoutConstantsData()

const INITIALSTATE = {
    layoutConstraintsData:LayoutConstraintsData(),
    cryptext : "",
    LIVE_URL : "",
    STAT_VIDEO_URL : "",
    STAT_AUDIT_URL:"",
    STAT_LIVE_URL : "",
    STAT_EXPORT_URL :"",
    STAT_DASHBOARD_URL :""
}


export const LayoutReducer = (state=INITIALSTATE , action:any)=>{

    switch (action.type){

        case "SET_DashboardPages" :
            const {layoutConstraintsDataNameChange,layoutConstraintsDataValueChange}=action.payload
            return {
                ...state,
                layoutConstraintsData:{...state.layoutConstraintsData, [layoutConstraintsDataNameChange]:layoutConstraintsDataValueChange}
            }
        case "GET_SEGMENT_URL" : {
                return {
                ...state,
                url_display_segment: action.payload.url_display_segment,
                }
            }
        case "SET_CRYPT_TEXT" : {
            return {
                ...state,
                cryptext: action.payload,
                }
        }
        case "SET_PHOTOPRISM_URL" : {
            return {
                ...state,
                PHOTOPRISM_URL: action.payload,
                }
        }
        case "SET_SVG_MATOR_URL" : {
            return {
                ...state,
                SVG_MATOR_URL: action.payload,
                } 
        }
        case "SET_VIDEO_URL" : {
            return {
                ...state,
                VIDEO_URL: action.payload,
            } 
        }
        default:{
            return state
        }
        case "SET_LIVE_URL" : {
            return {
                ...state,
                LIVE_URL: action.payload,
                } 
        }
        case "SET_STAT_VIDEO_URL" : {
            return {
                ...state,
                STAT_VIDEO_URL: action.payload,
                } 
        }
        case "SET_STAT_AUDIT_URL" : {
            return {
                ...state,
                STAT_AUDIT_URL: action.payload,
                } 
        }
        case "SET_STAT_LIVE_URL" : {
            return {
                ...state,
                STAT_LIVE_URL: action.payload,
                } 
        }
        case "SET_STAT_EXPORT_URL" : {
            return {
                ...state,
                STAT_EXPORT_URL: action.payload,
                } 
        }
        case "SET_STAT_DASHBOARD_URL" : {
            return {
                ...state,
                STAT_DASHBOARD_URL: action.payload,
                } 
        }

    }

}
