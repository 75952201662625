const ProfileIpIntegrated = ({language , token}) => {
  const newProfileIpIframe = "https:" +
  window.process.env.URL_Profile_Ip
  +  "?lang=" + language + "&token=" +
    token
  return (
    <iframe
      id="iframe_profileIp_display"
      src={newProfileIpIframe}
      allowFullScreen
      frameBorder={"0"}
      style={{ width: '100%', height: '98%', overflow: 'hidden' }}
    />
  )
}

export default ProfileIpIntegrated