const ProgrammeNewIntegrated = ({language , token}) => {
    const newProgrammeNewIframe = "https:" +
    window.process.env.URL_ProgrammeNew
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_ProgrammeNew_display"
        src={newProgrammeNewIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default ProgrammeNewIntegrated