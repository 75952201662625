import React from "react";
// import {Button, Col, Row} from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@mui/material";
export const RecoveryPassword = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} className={"col-connexion_recovery-pass"}>
      <Grid item xs={12}>
        <Typography variant="body1" className={"span_connexion"}>
          {t("forgetPassword.RecoveryEmail")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ fontSize: "14px" }}
          className={"span_connexion"}
        >
          {t("forgetPassword.CheckYourEmailBox")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ fontSize: "14px" }}
          className={"span_connexion"}
        >
          {t("forgetPassword.NotFoundEmailMsg")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                history.push("/connexion");
              }}
              className={"spn_chbx"}
              fullWidth
              variant="contained"
              color="primary"
            >
              {t("forgetPassword.BackToLogIn")}
            </Button>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="a"
              href="#/"
              className={"spn_chbx"}
              onClick={() => {
                history.push("/contactClient");
              }}
            >
              {t("forgetPassword.ContactClient")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // <Row gutter={[0, 35]} className={'col-connexion_recovery-pass'}>
    //     <Col span={24}>
    //         <span className={"span_connexion"}>
    //             {t("forgetPassword.RecoveryEmail")}
    //         </span>
    //     </Col>
    //     <Col span={24}>
    //         <span style={{fontSize:"14px"}} className={"span_connexion"}>
    //         {t("forgetPassword.CheckYourEmailBox")}
    //         </span>
    //     </Col>
    //     <Col span={24}>
    //         <span style={{fontSize:"14px"}} className={"span_connexion"}>
    //         {t("forgetPassword.NotFoundEmailMsg")}
    //         </span>
    //     </Col>
    //     <Col span={24}>
    //         <Row gutter={[20, 20]} >
    //             <Col span={24}>
    //                 <Button  onClick={()=>{history.push("/connexion")}} className={"spn_chbx"} style={{width:"100%"}}type="primary" >{t("forgetPassword.BackToLogIn")}
    //                 </Button>
    //             </Col>
    //             <Col onClick={()=>{history.push("/contactClient")}}>
    //                 <a className={"spn_chbx"} href="#/">
    //                     {t("forgetPassword.ContactClient")}</a>
    //             </Col>
    //         </Row>
    //     </Col>
    // </Row>
  );
};
