import {useDispatch} from "react-redux";
import {setConnexionOnchange} from "../store/connexionActions";
import {useHistory} from "react-router-dom";
import {GraphQLFetchData} from "./graphQLFetchData";
import {setConnexionConstraintDataOnchange} from '../store/connexionActions'
import {setPersistActions} from "../../redux/persistStore/persistActions";
import { Form} from 'antd'
import {useAppSelector} from "../../redux/hooks";

export const Hooks = () =>{
    const dispatch = useDispatch()
    const history = useHistory();
    const {Connexion} = GraphQLFetchData()
    const [form] = Form.useForm();
    const rememberMe = useAppSelector((state) => state.PersistReducer.userConstantsData)
    const connexion =() => {
        try
        { Connexion()}
        catch(error){
        };
        
    }
    const onFinish = (values: any) => {
        
        dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:true}))
        connexion()
        
        if(rememberMe.storageIsRememberMe === true){
            // add value to store
            dispatch(setPersistActions({persistNameChange:"storageUsername",persistValueChange:values.username}))
            dispatch(setPersistActions({persistNameChange:"storagePassword",persistValueChange:values.password}))

            // Add to LocalStorage
            localStorage.setItem('storageUsername',values.username);
            localStorage.setItem('storagePassword',values.password);
        }

    };

    const onFinishFailed = (errorInfo: any) => {
    };

    const connexionOnChange = async (event:React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setConnexionOnchange({
            ConnexionNameChange: event.target.name,
            ConnexionValueChange: event.target.value
        }));
        if(event.target.name === "username"){
            localStorage.setItem("storageUsername",event.target.value);
        }if(event.target.name === "Password"){
            localStorage.setItem("storagePassword",event.target.value);
        }
    }
    const connexionOnChangeButton = (e:any) =>{
        // add value to store
        dispatch(setPersistActions({persistNameChange:"storageIsRememberMe",persistValueChange:e.target.checked}))

        // Add to LocalStorage
        localStorage.setItem('storageIsRememberMe',e.target.checked);
    }

    const toForgotPassword = () =>{
        history.push("/forgot-password")
    }

    return({
        onFinish,
        onFinishFailed,
        connexionOnChange,
        form,
        connexionOnChangeButton,
        toForgotPassword
    })
}