
export function setLayoutDashboardPages(e:any){
    return{
        type:"SET_DashboardPages",
        payload:e,
    }
}
//******************** getcrypttext listsegment actions***************//

export const setSegmentList = (e : any) => {
   

    return{
          type: "GET_SEGMENT_URL",
          payload:e,
      }
  }

export const setCryptText = (e :any) => {
    
    return{
        type: "SET_CRYPT_TEXT",
        payload:e,
    }
}

export const setPhotoPrismUrl = (e : any) => {
    return{
        type: "SET_PHOTOPRISM_URL",
        payload:e,
    }
}

export const setSVGmatorUrl = (e : any) => {
    return{
        type: "SET_SVG_MATOR_URL",
        payload:e,
    }
}

export const setLiveUrl = (e : any) => {
    return{
        type: "SET_LIVE_URL",
        payload:e,
    }
}

export const setStatVideoUrl = (e : any) => {
    return{
        type: "SET_STAT_VIDEO_URL",
        payload:e,
    }
}

export const setStatAuditUrl = (e : any) => {
    return{
        type: "SET_STAT_AUDIT_URL",
        payload:e,
    }
}

export const setStatLiveUrl = (e : any) => {
    return{
        type: "SET_STAT_LIVE_URL",
        payload:e,
    }
}

export const setStatExportUrl = (e : any) => {
    return{
        type: "SET_STAT_EXPORT_URL",
        payload:e,
    }
}

export const setStatDashboardUrl = (e : any) => {
    return{
        type: "SET_STAT_DASHBOARD_URL",
        payload:e,
    }
}



export const setVideo = (e : any) => {
    return{
        type: "SET_VIDEO_URL",
        payload:e,
    }
}