import {useDispatch, useSelector} from "react-redux";
import {
    setResetPasswordConstraintDataOnchange,
    setResetPasswordOnchange
} from "../store/resetPasswordAction";
import {GraphQLFetchData} from "./graphQLFetchData";
import {useAppSelector} from "../../redux/hooks";



export  const Hooks=()=> {
    const dispatch = useDispatch()
    // use Selector redux
    const values = useAppSelector((state) => state.ResetPasswordReducer)
    const {ResetPassword}=GraphQLFetchData(values)
//******************ResetPassword************************//
    const resetPasswordOnChange = (event:any) => {
        dispatch(setResetPasswordOnchange({
            resetPasswordNameChange: event.target.name,
            resetPasswordValueChange: event.target.value
        }));
    };

    const handleSubmit =()=>{
        dispatch(setResetPasswordConstraintDataOnchange({constraintDataNameChange:"loadingResetPassword",constraintDataValueChange:true}))
        ResetPassword()
    }

    return({
        resetPasswordOnChange,
        handleSubmit,
        values,
    })
}