const HomeVideoPushIntegrated = ({language , token}) => {
    const newHomeVideoPushIframe = "https:" +
    window.process.env.URL_HomeVideoPush
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_homeVideoPush_display"
        src={newHomeVideoPushIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default HomeVideoPushIntegrated