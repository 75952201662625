import {OptionsProfile} from "../layout/components/OptionsProfile/OptionsProfile"
import {ApiKey}  from "../layout/components/CléApi/ApiKey"
import DashboardIntegrated from "../IntegratedDashboard/DashboardIntegrated"
import ProfileGroupIntegrated from "../IntegratedProfile/ProfileGroupIntegrated"
import ProfileIpIntegrated from "../IntegratedProfile/ProfileIpIntegrated"
import ProfileUserIntegrated from "../IntegratedProfile/ProfileUserIntegrated";
import ThemeIntegrated from "../IntegratedClassment/ThemeIntegrated";
import CanalIntegrated from "../IntegratedClassment/CanalIntegrated";
import PlayListIntegrated from "../IntegratedMedia.jsx/PlayListIntegrated";
import PodcastIntegrated from "../IntegratedMedia.jsx/PodcastIntegrated";
import VideosIntegrated from "../IntegratedMedia.jsx/VideosIntegrated";
import AbonnementGroupIntegrated from "../IntegratedAbonnement/AbonnementGroupIntegrated";
import AbonnementUserIntegrated from "../IntegratedAbonnement/AbonnementUserIntegrated";
import LivesIntegrated from "../IntegratedMedia.jsx/LivesIntegrated";
import ServeurIntegrated from "../IntegratedConfiguration/ServeurIntegrated";
import ActualityIntegrated from "../IntegratedConfiguration/ActualityIntegrated";
import ClientIntegrated from "../IntegratedConfiguration/ClientIntegrated";
import ConfigurationIntegrated from "../IntegratedConfiguration/ConfigurationIntegrated";
import MetadonnesIntegrated from "../IntegratedConfiguration/MetadonnesIntegrated";
import MoocIntegrated from "../IntegratedConfiguration/MoocIntegrated";
import PresetIntegrated from "../IntegratedConfiguration/PresetIntegrated";
import ReferentIntegrated from "../IntegratedConfiguration/ReferentIntegrated";
import Profile from "../layout/components/Profile/Profile";
import ChutierIntegrated from "../IntegratedMedia.jsx/ChutierIntegrated";
import EvenementIntegrated from "../IntegratedMedia.jsx/EvenementIntegrated";
import SondageIntegrated from "../IntegratedInteractivité/SondageIntegrated";
import HomeAcceuilleIntegrated from "../IntegratedConfiguration/HomeAcceuilleIntegrated";
import HomeConfigurationIntegrated from "../IntegratedConfiguration/HomeConfigurationIntegrated";
import HomeVideoPushIntegrated from "../IntegratedConfiguration/HomeVideoPushIntegrated";
import PlayerParametresIntegrated from "../IntegratedConfiguration/PlayerParametresIntegrated";
import GroupRMBoxIntegrated from "../IntegratedBoitier/GroupRMBoxIntegrated";
import RMBoxIntegrated from "../IntegratedBoitier/RMBoxIntegrated";
import IpAuthIntegrated from "../IntegratedBoitier/IpAuthIntegrated";
import ZoneIntegrated from "../IntegratedBoitier/ZoneIntegrated";
import DirectionIntegrated from "../IntegratedEncoudeur/DirectionIntegrated";
import IpTvsIntegrated from "../IntegratedEncoudeur/IpTvsIntegrated";
import StationIntegrated from "../IntegratedEncoudeur/StationIntegrated";
import DashboardStatsIntegrated from "../IntegratedStatistics/DashboardStatIntegrated";
import StatsAuditIntegrated from "../IntegratedStatistics/StatsAuditIntegrated";
import StatsConsommationIntegrated from "../IntegratedStatistics/StatsConsommationIntegrated";
import StatsDigitalIntegrated from "../IntegratedStatistics/StatsDigitalIntegrated";
import StatsDirectIntegrated from "../IntegratedStatistics/StatsDirectIntegrated";
import StatsExportsIntegrated from "../IntegratedStatistics/StatsExportsIntegrated";
import StatsStockageIntegrated from "../IntegratedStatistics/StatsStockageIntegrated";
import StatsVideosIntegrated from "../IntegratedStatistics/StatsVideosIntegrated";
import ProgrammeIntegrated from "../IntegratedDisplay/ProgrammeIntegrated";
import GrilleDesProgrammesIntegrated from "../IntegratedDisplay/GrilleDesProgrammesIntegrated";
import ProgrammeNewIntegrated from "../IntegratedDisplayNew/ProgrammeNewIntegrated";
import GrilleDesProgrammesNewIntegrated from "../IntegratedDisplayNew/GrilleDesProgrammesNewIntegrated";
import SegmentIntegrated from "../IntegratedDisplayNew/SegmentIntegrated";
const routes = [
    {
        path: '/display_new/new_template_manager',
        component: ProgrammeNewIntegrated,
        key: '/display_new/new_template_manager',
    },
    {
        path: '/display_new/new_template_segment',
        component: SegmentIntegrated,
        key: '/display_new/new_template_segment',
    },
    {
        path: '/display_new/schedule',
        component: GrilleDesProgrammesNewIntegrated,
        key: '/display_new/schedule',
    },
    ///////
    {
        path: '/display/template_manager',
        component: ProgrammeIntegrated,
        key: '/display/template_manager',
    },
    {
        path: '/display/schedule',
        component: GrilleDesProgrammesIntegrated,
        key: '/display/schedule',
    },
    ////////
    {
        path: '/stats/dashboard',
        component: DashboardStatsIntegrated,
        key: '/stats/dashboard',
    },
    {
        path: '/stats/acces',
        component: StatsAuditIntegrated,
        key: '/stats/acces',
    },
    {
        path: '/stats/consumptions',
        component: StatsConsommationIntegrated,
        key: '/stats/consumptions',
    },
    {
        path: '/stats/mooc',
        component: StatsDigitalIntegrated,
        key: '/stats/mooc',
    },
    {
        path: '/stats/live',
        component: StatsDirectIntegrated,
        key: '/stats/live',
    },
    {
        path: '/stats/list_videos',
        component: StatsExportsIntegrated,
        key: '/stats/list_videos',
    },
    {
        path: '/stats/stockage',
        component: StatsStockageIntegrated,
        key: '/stats/stockage',
    },
    {
        path: '/stats/vod',
        component: StatsVideosIntegrated,
        key: '/stats/vod',
    },
    /////////
    {
        path: '/specifics/direction',
        component: DirectionIntegrated,
        key: '/specifics/direction',
    },
    {
        path: '/specifics/iptv',
        component: IpTvsIntegrated,
        key: '/specifics/iptv',
    },
    {
        path: '/specifics/station',
        component: StationIntegrated,
        key: '/specifics/station',
    },
    /////////
    {
        path: '/boitier/rmboxgroup',
        component: GroupRMBoxIntegrated,
        key: '/boitier/rmboxgroup',
    },
    {
        path: '/boitier/rmbox',
        component: RMBoxIntegrated,
        key: '/boitier/rmbox',
    },
    {
        path: '/boitier/zone',
        component: ZoneIntegrated,
        key: '/boitier/zone',
    },
    {
        path: '/boitier/autoriseip',
        component: IpAuthIntegrated,
        key: '/boitier/autoriseip',
    },
    ///////
    {
        path: '/configuration/home/conf',
        component: HomeConfigurationIntegrated,
        key: '/configuration_accueil_page',
    },
    {
        path: '/configuration/home/list',
        component: HomeAcceuilleIntegrated,
        key: '/video_accueil_page',
    },
    {
        path: '/configuration/home/push',
        component: HomeVideoPushIntegrated,
        key: '/push_accueil_page',
    },
    {
        path: '/configuration/metaData',
        component: MetadonnesIntegrated,
        key: '/metadata_configuration',
    },
    {
        path: '/configuration/configuration_mooc',
        component: MoocIntegrated,
        key: '/mooc_configuration',
    },
    {
        path: '/configuration/server',
        component: ServeurIntegrated,
        key: '/serveur_configuration',
    },
    {
        path: '/configuration/presets',
        component: PresetIntegrated,
        key: '/preset_configuration',
    },
    {
        path: '/configuration/referrers',
        component: ReferentIntegrated,
        key: '/ref_configuration',
    },
    {
        path: '/configuration/client',
        component: ClientIntegrated,
        key: '/client_configuration',
    },
    {
        path: '/configuration/news',
        component: ActualityIntegrated,
        key: '/actuality_configuration',
    },
    {
        path: '/configuration/player_parameters',
        component: PlayerParametresIntegrated,
        key: '/player_parameters',
    },
    {
        path: '/media/lives',
        component: LivesIntegrated,
        key: '/lives',
    },
    {
        path: '/media/video',
        component: VideosIntegrated,
        key: '/Medias',
    },
    {
        path: '/media/podcast',
        component: PodcastIntegrated,
        key: '/podcast',
    },
    {
        path: '/media/playlist',
        component: PlayListIntegrated,
        key: '/playlist',
    },
    {
        path: '/media/chutier',
        component: ChutierIntegrated,
        key: '/chutier',
    },
    {
        path: '/media/event',
        component: EvenementIntegrated,
        key: '/event',
    },
    {
        path: '/interactivity/sondage',
        component: SondageIntegrated,
        key: '/sondage',
    },
    {
        path: '/configuration/display_options',
        component: OptionsProfile,
        key: '/OptionDaffichage',
    },
    {
        path: '/support/Api/Key',
        component: ApiKey,
        key: '/ApiKey',
    },
    {
        path: '/',
        component: DashboardIntegrated,
        key: '/',

    },
    {
        path: '/dashboard',
        component: DashboardIntegrated,
        key: '/dashboard',

    },
    {
        path: '/abonnement/group',
        component: AbonnementGroupIntegrated,
        key: '/abonnement/group',
    },
    {
        path: '/abonnement/user',
        component: AbonnementUserIntegrated,
        key: '/abonnement/user',
    },
    {
        path: '/profiles/user',
        component: ProfileUserIntegrated,
        key: '/profiles/user',
    },
    {
        path: '/profiles/group',
        component: ProfileGroupIntegrated,
        key: '/profiles/group',
        
    },
    {
        path: '/profiles/ip',
        component: ProfileIpIntegrated,
        key: '/profiles/ip',
        
    },
    {
        path: '/classifying/theme',
        component: ThemeIntegrated,
        key: '/theme_classement',
    },
    {
        path: '/classifying/canal',
        component: CanalIntegrated,
        key: '/canal_classement',
    },
    {
        path: '/profile/user/profilconnected',
        component: Profile,
        key: '/profile',
    },
   
];
export default routes;
