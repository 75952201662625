
 const setUpMenuData = () => {
  const x : any = []
  const z : any = Object.entries((window as any).menu_app)
  const y = (b:any,u:any,i:any,o:any,p:any=[],m:any,Id:any) => {
    return {
      indicatorSolutionOnly : b || null,
      id: Id || null,
      key: u  || null,
      iconClass: i || null,
      title: o || null,
      MenuItem: p || null,
      Permissions: m || null
    }
  }
  for(let i=0 ; i< z.length-1 ; i++){
    if(z[i][0].indexOf(".") === -1){
      x.push(y("/"+z[i][0],z[i][1]?.title,z[i][1]?.icon,z[i][1]?.translate,[],z[i][1]?.permission,i+1))
    }else if(z[i][0].split(".").length === 2){
      x.find((item:any) => item.indicatorSolutionOnly.toLowerCase() === ("/"+z[i][0].split(".")[0]).toLowerCase())?.MenuItem.push(y("/"+z[i][0].split(".").slice(0,2).join("/"),z[i][1]?.title as any,z[i][1]?.icon,z[i][1]?.translate,[],z[i][1]?.permission,i+1))
    }else if(z[i][0].split(".").length === 3){
      x.find((item:any) => item.indicatorSolutionOnly.toLowerCase() === ("/"+z[i][0].split(".")[0]).toLowerCase())?.MenuItem.find((item:any) => item.indicatorSolutionOnly.toLowerCase() === ("/"+z[i][0].split(".")[0]+"/"+z[i][0].split(".")[1]).toLowerCase())?.MenuItem.push(y("/"+z[i][0].split(".").slice(0,3).join("/"),z[i][1]?.title as any,z[i][1]?.icon,z[i][1]?.translate,[],z[i][1]?.permission,i+1))
    }
  }
  
  return x
}


const SubMenuData = setUpMenuData()

export default SubMenuData;
