const PlayListIntegrated = ({language , token}) => {
    const newPlayListIframe = "https:" +
    window.process.env.URL_PlayList
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_playlist_display"
        src={newPlayListIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default PlayListIntegrated