import React, { useEffect, useLayoutEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Logo from "../../../assets/logo_dam.png";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Collapse } from "@mui/material";
import SubMenuData from "../../utils/DataMenu";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import "./SideBar.css";
import { GlobalHooks } from "../../../utils/Hooks";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useAppSelector } from "../../../redux/hooks";
import HasPermission from "../HasPermission/HasPermission";
import { makeStyles } from '@mui/styles';
import Cookies from "js-cookie";
const Sidebar = ({ open, handleOnCollapse }) => {
  const permissions = useAppSelector((state) => state.ConnexionReducer.constraintData.permissions)
  const isAdmin = useAppSelector((state) => state.ConnexionReducer.constraintData.isAdmin)
  const {cookies} = GlobalHooks()
  const [sectionOpen, setSectionOpen] = useState([]);
  const [moduleChosen,setModuleChosen] = useState('/dashboard')
  // const [openFirst,setOpenFirst] = useState([])
  const [redic,setRedic] = useState([])

  const { t } = useTranslation();
  const history = useHistory();
  const findOutIfOpened = (e) => {
    const index = sectionOpen.indexOf(e);
    if (index >= 0) {
      const newItems = sectionOpen.slice(0);
      newItems.splice(index, 1);
      return setSectionOpen(newItems);
    } else {
      return setSectionOpen((prev) => [...prev, e]);
    }
    
  };
  
  const dissableModulesByItSelf = (y) => {
    if(y === "/configuration/player_parameters" || y === "/stats/consumptions" || y === "/stats/stockage" || y === "/stats/mooc"){
      return true 
    }else{
      return false
    }
  } 

  const disabledModulesByParent = (x) => {
      if(x === "/abonnement" || x === "/interactivity" || x === "/specifics" || x === "/display" || x === "/display_new" || x === "/skin") {
        return true
      } else {
        return false
      }
  }


  useEffect(()=>{
    if(window.location.pathname.length >=1){
      if(window.location.pathname.split("/").length > 2){
        sectionOpen.push("/" + window.location.pathname.split("/")[1])
        sectionOpen.push(window.location.pathname)
        setModuleChosen(window.location.pathname)
      }
      if(window.location.pathname.split("/").length > 3){
        sectionOpen.push("/" + window.location.pathname.split("/")[1])
        sectionOpen.push("/" + window.location.pathname.split("/")[1] + "/" + window.location.pathname.split("/")[2])
        sectionOpen.push(window.location.pathname)
        setModuleChosen(window.location.pathname)
      }
    }
  },[])
  useLayoutEffect(()=>{
    if(moduleChosen !== "/dashboard" && (sectionOpen.indexOf("/dashboard") >= 0)){
      const newItems = sectionOpen.slice(0);
      newItems.splice(sectionOpen.indexOf("/dashboard"), 1);
      return setSectionOpen(newItems);
    }
  },[moduleChosen])
  return (
    <>
{cookies.LayoutTheme === "L3" || cookies.LayoutTheme === "L2" ? <></>: (
    <Box

className={`sidebarGlobal ${open ? "mobileee" : ""}`}
sx={{p:open ?"10px":0, marginTop : cookies.LayoutTheme === "L1" ? "64px" : "", width: open ? 250 : 90, position: "fixed", zIndex:5 ,height:"100vh", backgroundColor: cookies.ColorTheme === "default" ? "#F8FBFF" : cookies.ColorTheme === "c2" ? "#cee3e7" : cookies.ColorTheme === "c3" ? "#426c7a" : cookies.ColorTheme === "c4" ? "#8499e4" : cookies.ColorTheme === "c5" ? "#11776c" : "#aaaaaa"}}
>
      {cookies.LayoutTheme === "default" && (
        <>
       <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          height: 64,
          paddingRight: "3px",
        }}
      >
        {open && <img src={Logo} alt="Logo" height={40} />}
        {open ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              color: "black",
              width: "45px",
              height: "70px",
              marginLeft: "auto",
            }}
            onClick={handleOnCollapse}
          >
            <MenuOpenIcon />
          </IconButton>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              color: "black",
              width: "80px",
              height: "70px",
            }}
            onClick={handleOnCollapse}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ color: "white" }} /> 
      </>
      )}
      <List className="sidebar">
        {permissions &&
         SubMenuData &&
          SubMenuData.map((e) => (
            <div key={e.id}>
              <HasPermission redi={redic} permission={e.Permissions} isAdmin={isAdmin} per={permissions} childs={e} allowed={!(((permissions?.userData?.modules[e.Permissions]) !== true) && (!isAdmin) )} >
              <ListItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: "6px",
                  backgroundColor: (open && sectionOpen.indexOf(e.indicatorSolutionOnly) !== -1) || (moduleChosen === e.indicatorSolutionOnly) ? cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" : ""  ,
                  borderRadius:"12px",
                  marginLeft:"1px",
                  gap:"10px"
                }}
                onClick={() => {
                    findOutIfOpened(e.indicatorSolutionOnly);
                    if(!(e.MenuItem.length > 0)){
                      setModuleChosen(e.indicatorSolutionOnly)
                      history.push(e.indicatorSolutionOnly)
                    }  
                }}
              >
                {((open && (sectionOpen.indexOf(e.indicatorSolutionOnly) === -1)) ) ? (
                  <i style={{color: sectionOpen.indexOf(e.indicatorSolutionOnly) !== -1  ? "black" :""}} className={`${moduleChosen === e.indicatorSolutionOnly ? e.iconClass+'white' : e.iconClass} ${((e.indicatorSolutionOnly === moduleChosen)&&(!open)) ? 'blackerr' : ""}`}></i>
                  ):(
                  <i className={`${e.iconClass+'white'}`}></i>
                )}

                {open && (
                  <ListItemText sx={{ color:  sectionOpen.indexOf(e.indicatorSolutionOnly) !== -1 || moduleChosen === e.indicatorSolutionOnly ? "white" : e.indicatorSolutionOnly === moduleChosen ? "black" : Cookies.get('ColorTheme') === "default" ? "#6C737F" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84" }} primary={t(e.title)} />
                )}
                {open &&
                  e.MenuItem.length >= 1 &&
                  (!(sectionOpen.indexOf(e.indicatorSolutionOnly) >= 0) ? (
                      e.key !== "/abonnement" ? (
                        <KeyboardArrowRightIcon sx={{ color: sectionOpen.indexOf(e.indicatorSolutionOnly) !== -1 ? "white" : "black" }} />
                      ):(
                        <DoNotDisturbIcon sx={{ color: "black" }} />
                      )
                      ) : (
                      <KeyboardArrowDownIcon sx={{ color: sectionOpen.indexOf(e.indicatorSolutionOnly) !== -1 ? "white" : "black" }} />
                  ))}
              </ListItem>
              {e.MenuItem.length >= 1 &&
                sectionOpen.indexOf(e.indicatorSolutionOnly) >= 0 &&
                e.MenuItem.map((u) => (
                  <HasPermission redi={redic} permission={u.Permissions} isAdmin={isAdmin} per={permissions} childs={u} allowed={!(((permissions?.userData?.modules[u.Permissions]) !== true) && (!isAdmin) )} >
                  <Collapse key={u.id} in={sectionOpen.indexOf(e.indicatorSolutionOnly) >= 0} timeout="auto" unmountOnExit>
                    <List
                      sx={{ paddingLeft: "20px" }}
                      component="div"
                      disablePadding
                      onClick={()=>findOutIfOpened(u.indicatorSolutionOnly)  }
                    >
                      <ListItem
                      onClick={()=>{if(!(u.MenuItem.length > 0) && (!disabledModulesByParent(e.indicatorSolutionOnly) && !dissableModulesByItSelf(u.indicatorSolutionOnly))){setModuleChosen(u.indicatorSolutionOnly); history.push(u.indicatorSolutionOnly)}}}
                        sx={{
                          color: "black",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap:"10px"

                        }}
                      >
                        <i className={`${u.iconClass} ${((u.indicatorSolutionOnly === moduleChosen)&&(!open)) ? 'blackerr' : ""}`}></i>
                        {open && (
                        <ListItemText
                          sx={{ color: u.indicatorSolutionOnly === moduleChosen ? "black" : Cookies.get('ColorTheme') === "default" ? "#6C737F" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"  ,textShadow : u.indicatorSolutionOnly === moduleChosen ? "-1px 0px 0px black" : "none"}}
                          primary={t(u.title)}
                        />
                        )}
                        {open && (
                          u.MenuItem.length >= 1 &&
                          (!(sectionOpen.indexOf(u.indicatorSolutionOnly) >= 0) ? (
                                <KeyboardArrowRightIcon sx={{ color: "black" }} />
                              ) : (
                              <KeyboardArrowDownIcon sx={{ color: "black" }} />
                        )))}
                        {open && (
                          disabledModulesByParent(e.indicatorSolutionOnly) || dissableModulesByItSelf(u.indicatorSolutionOnly) ? (
                            <DoNotDisturbIcon sx={{ color: "black" }} />
                          ):(<></>)
                        )}
                        </ListItem>
                    </List>
                    {u.MenuItem.length > 0 &&
                   sectionOpen.indexOf(u.indicatorSolutionOnly) >= 0 &&
                   u.MenuItem.map((k) => (
                    <HasPermission redi={redic} permission={k.Permissions} allowed={!(((permissions?.userData?.modules[k.Permissions]) !== true) && (!isAdmin) )} >

                     <Collapse key={k.id} in={sectionOpen.indexOf(u.indicatorSolutionOnly) >= 0} timeout="auto" unmountOnExit>
                       <List
                         sx={{ paddingLeft: "40px" }}
                         component="div"
                         disablePadding
                       >
                         <ListItem
                         onClick={()=>{if(!(k.MenuItem.length > 0)){setModuleChosen(k.indicatorSolutionOnly) ;history.push(k.indicatorSolutionOnly)}}}
                           sx={{
                             color: "black",
                             display: "flex",
                             alignItems: "center",
                             cursor: "pointer",
                             gap:"10px"
   
                           }}
                         >
                           <i className={`${k.iconClass} `}></i>
                           {open && (
                           <ListItemText
                             sx={{ color: k.indicatorSolutionOnly === moduleChosen ? "black" : Cookies.get('ColorTheme') === "default" ? "#6C737F" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84" ,textShadow : k.indicatorSolutionOnly === moduleChosen ? "-1px 0px 0px black" : "none"}}
                             primary={t(k.title)}
                           />
                           )}
                           </ListItem>
                       </List>
                     </Collapse>
                     </HasPermission>
                   ))}
                  </Collapse>
                  </HasPermission>
                ))}
              </HasPermission>
            </div>
          ))}
      </List>
    </Box>
    )}
    </>
  );
};

export default Sidebar;
