import React from 'react';
import {
    FormControl,
  } from "@mui/material";
import '../forgetPassword.scss'
import {Hooks} from "../utils/hooks";
import {RecoveryPassword} from "./recoveryPassword";
import {ForgetPassword} from "./forgetPassword";

export const FormForgetPassword =()=>{
    // const [form] = Form.useForm();

    const{handleSubmit,values}=Hooks()

    return(
        <FormControl
            // form={form}
            // layout="horizontal"
            // name="Connection-form"
            className={"Connection-form-forget-pass"}
            // onFinish={handleSubmit}
        >
            {!values.constraintData.passwordSent ?
                <ForgetPassword handleSubmit={handleSubmit}/>
                :
                <RecoveryPassword/>
            }
        </FormControl>
    )
}
