export const ProfileConstantsData = ()=>{
    const ShowButton:()=>{
        showRegister: boolean
    }=()=>{
    return({
        showRegister : false
    })
    }
    const ProfileConstraintsData:() => {
        Id: number
        Username: string
        UsernameCanonical: string
        Email: string
        EmailCanonical: string
        Enabled: boolean
        Salt: string
        Password: string
        LastLogin: any
        ConfirmationToken: string
        PasswordRequestedAt: any
        Roles: string
        FirstName: string
        LastName: string
        ConnectionType: number
        Vignette: string
        Phone: string
        Mobile: string
        Description: string
        Adresse: string
        City: string
        Society: string
        PostalCode: string
        NumberPerson: number
        UserType: string
        TokenValidation: string
    } = () => {
        return (
            {
                Id: 0,
                Username: "",
                UsernameCanonical: "",
                Email: "",
                EmailCanonical: "",
                Enabled: false,
                Salt: "",
                Password: "",
                LastLogin: "",
                ConfirmationToken: "",
                PasswordRequestedAt: "",
                Roles: "",
                FirstName: "",
                LastName: "",
                ConnectionType: 0,
                Vignette: "",
                Phone: "",
                Mobile: "",
                Description: "",
                Adresse: "",
                City: "",
                Society: "",
                PostalCode: "",
                NumberPerson: 0,
                UserType: "",
                TokenValidation: "",
            }
        )
    }
    const ProfileConstraintsDataUpdated:() => {
        Id: number
        Email: string
        FirstName: string
        LastName: string
        Phone: string
        Adresse: string
        City: string
        Society: string
        PostalCode: string
        Vignette: string
        NumberPerson: number
        Username: string
    } = () => {
        return (
            {
                Id:0,
                Email:"",
                FirstName:"",
                LastName:"",
                Vignette:"",
                Phone:"",
                Adresse:"",
                City:"",
                Society:"",
                PostalCode:"",
                NumberPerson:0,
                Username:"",

            }
        )
    }

    return({
        ProfileConstraintsData,
        ProfileConstraintsDataUpdated,
        ShowButton
    })

}