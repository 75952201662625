import React, { useState,useEffect,useRef } from 'react';
import { makeStyles } from '@mui/styles';
import "../../../connexion/Connexion.scss"
import "./DoubleAuth.scss"
import svg from "../../../assets/Group.svg";
import logo from "../../../assets/logo_dam.png";
import { useTranslation } from "react-i18next";
import { GraphQLFetchData } from '../../../connexion/utils/graphQLFetchData';
import {
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import fr from "../../../assets/fr.png";
import en from "../../../assets/en.png";
import i18next from "i18next";
import { GlobalHooks } from "../../../utils/Hooks";

const useStyles = makeStyles(() => ({
  
  inputBox: {
    width: '15%',
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1.5rem',
    borderRadius: '4px',
    border: `1px solid black`,
    outline: 'none',
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
  },
}));

const DoubleAuth = () => {
    const loading = useAppSelector((state) => state.ConnexionReducer.constraintData);
    const message = useAppSelector((state) => state.ConnexionReducer.constraintData.messageCodeWrong)
    const {codeConnexion} = GraphQLFetchData()
    const { t } = useTranslation();
    const classes = useStyles();
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [wrongCode,setWrongCode] = useState(message)
    const {cookies,setCookie} = GlobalHooks()
    
    useEffect(()=>{
      setWrongCode(message)
    },[message])

    const handleCodeChange = (index, event) => {
        const digit = event.target.value;
        if (!isNaN(digit) || digit === '') {
          const updatedCode = [...code];
          updatedCode[index] = digit;
          setCode(updatedCode);
    
          if (digit !== '') {
            focusInput(index + 1);
          }
        }
      };

      const codeLogin = ()=>{
        if (code.every((digit) => digit !== '')) {
          codeConnexion(code.join(""))
      }else{
        setWrongCode("Your Code Is Wrong")
      }
      }
    
      const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
          if (!code[index] && index > 0) {
            focusInput(index - 1);
          } else {
            const updatedCode = [...code];
            updatedCode[index] = '';
            setCode(updatedCode);
          }
        }
      };
    
      const handlePaste = (event) => {
        event.preventDefault();
        const pastedCode = event.clipboardData.getData('text').slice(0, code.length);
        const pastedDigits = pastedCode.split('');
        const updatedCode = pastedDigits.map((digit) => (isNaN(digit) ? '' : digit));
        setCode(updatedCode);
      };
    
      const focusInput = (index) => {
        if (index >= 0 && index < code.length) {
          inputRefs.current[index].focus();
        }
      };
    
      const changeLanguage = (e) => {
        i18next.changeLanguage(e);
        setCookie("language",e,{path:"/"})
      };

      return (
        <div className={"Connexion "}>
            <div className={"backgroundDiv"}>
                <img className={"icon-Group"} src={svg} />
            </div>
                <div className='fffff'>
                    <div className={"LogoDoubleDiv"}>
                        <img src={logo} />
                    </div>
              
                    <div className='bg-white-container'>
                        <p className='double-check-first'>{t("Checking your account")}</p>
                        <p className='double-check-second'>{t("Enter your confirmation code")}</p>
                        <div className={`adjustDouble`}>
                            {code.map((digit, index) => (
                                <input
                                key={index}
                                ref={(input) => (inputRefs.current[index] = input)}
                                className={classes.inputBox}
                                value={digit}
                                onChange={(event) => handleCodeChange(index, event)}
                                onKeyDown={(event) => handleKeyDown(index, event)}
                                onPaste={handlePaste}
                                maxLength={1}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                />
                            ))}
                        </div>
                          {wrongCode.length >= 1 && 
                            <Typography variant="body1" className="error">
                              {t(wrongCode)}
                            </Typography>
                          }
                        <button onClick={()=>codeLogin()} className={`send-button ${loading.loadingConnexionCode ? "disableCOde" : ""}`} style={{backgroundColor:"#029CE5"}}>{loading.loadingConnexionCode ? t("Loading...") : t("send")}</button>
                        <Box className="language-dropdown top-right">
            <Select
              sx={{ boxShadow: 'none',  '.MuiOutlinedInput-notchedOutline': { border: 0 }, }}
              style={{
                width: "100%",
                boxShadow: "none",
                borderRadius: "0",
                backgroundColor: "transparent",
                border:"none",
              }}
              value={i18next.language}
              onChange={(e) => changeLanguage(e.target.value)}
              IconComponent={() => null}
            >
              <MenuItem value={"fr"} >
                <img style={{marginRight:"0.5rem"}} src={fr} alt="Français" />
                FR
              </MenuItem>
              <MenuItem value={"en"} >
                <img style={{marginRight:"0.5rem"}}  src={en} alt="English" />
                EN
              </MenuItem>
            </Select>
            <div className="Languagee">{i18next.language.toLocaleUpperCase}</div>

          </Box>
                    </div>
                    </div>
        </div>
      );
    };
export default DoubleAuth;