import React from 'react'
import { GlobalHooks } from '../../utils/Hooks'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const Content = ({child , open}) => {
  const {cookies,setCookie} = GlobalHooks()

  return (
    <>
    {cookies.LayoutTheme === "L4" && (<ReplyAllIcon onClick={()=>setCookie("LayoutTheme","default",{path:"/"})} sx={{position:"absolute" ,cursor:"pointer", zIndex:"9", top:"15px" , left :"15px" ,backgroundColor:"transparent",color:cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84",width:"40px",height:"40px",borderRadius:"4px",padding:"5px"}} fontSize='medium' />)}
    <div style={{height: cookies.LayoutTheme === "L2" ? "calc(100vh - 134px)" : cookies.LayoutTheme === "L4" ? "100vh" : "calc(100vh - 84px)"}} className={`childContent ${cookies.LayoutTheme === "L4" ? "hiddenVanPlace" : ""} ${!open? 'smalleeer' : ""}`}>{child}</div>
    </>
  )
}

export default Content