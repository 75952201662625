const API_HOST_DAM = window.process.env.API_HOST_GLOBAL
// export const SEGMENTURL = API_HOST_DAM + "/programDisplay/display-segment-client?type=list"
// export const PHOTPRISM_API = API_HOST_DAM + "/video/get-url-photoprisme-fo"
// export const SVGMATORAPIurl = API_HOST_DAM +  "/programDisplay/params-display"
export const LIVE_URL = "https:" + API_HOST_DAM  + "/lives/video/get-url-list-live?type=list"
export const STAT_URL = "https:" + API_HOST_DAM  + "/video/video/get-url-list-video"
export  const OPTIONS = { 
  method: "GET", 
  headers: { 
    Accept: "application/json", 
    "Authorization" : "Bearer "+ window.process.env.API_TOKEN, 
  }, 
 
}; 