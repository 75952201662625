import React from "react";
import { Route,Switch,Redirect } from "react-router-dom";
import routes from "./routes";
import { useEffect,useState } from "react";
import i18next from "i18next";
import { GlobalHooks } from "../utils/Hooks";
import Cookies from "js-cookie";

function RoutingList() {
  const [language, setLanguage] = useState(i18next.language);
  const {cookies} = GlobalHooks()

  useEffect(() => {
    const handleLanguageChange = (newLang:any) => {
      setLanguage(newLang);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);
  return (
    <>
      {" "}
      {Cookies.get("authToken") && routes.map((item) => {
          return (
              <Route
                exact
                path={item.path}
                render={() => <item.component token={Cookies.get("authToken")} language={language as any} />}
                key={item.key}
              />
          );
      })}
    </>
  );
}

export default RoutingList;
