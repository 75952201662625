import axios from "axios";
import eventHub from "./eventHub";
import Cookies from "js-cookie";
let token = Cookies.get('authToken');

const refreshToken = async () =>{
    let url = window.process.env.CUSTOM_FIELD_API + '/token/refreshToken'
    return await axios.get(url,{   
        headers: {
          'Authorization': token ? 'Bearer ' + token :""
        }
    }).then(res=>{
        if((res.data.code === 200)  && (res.data.data.token.length > 0)) {
            token = res.data.data.token;
            Cookies.set('authToken',token)
            window.location.reload()
        }else{
            Cookies.set('authToken',null)
            eventHub.trigger("disconnect","")
        }
}).catch(err=>{
    Cookies.set('authToken',null)
    eventHub.trigger("disconnect","")
})
}

const axiosInstance = () => {
    const instance = axios.create({
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    });
  
    instance.interceptors.request.use(
      function (config) {
        const token = Cookies.get('authToken');
        config.headers.Authorization = "Bearer " + token;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  
    instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.data.code === 401 && error.response.data.message === "Expired JWT Token") {
          refreshToken()
      }else if (error.response.data.code === 401 && error.response.data.message === "Invalid JWT Token"){
            Cookies.set('authToken',null)
          eventHub.trigger("disconnect","")
      }
      return Promise.reject(error);
    })
    return instance;
  };

export default axiosInstance;