const ActualityIntegrated = ({language , token}) => {
    const newActualityIframe = "https:" +
    window.process.env.URL_Actuality
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_actuality_display"
        src={newActualityIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default ActualityIntegrated