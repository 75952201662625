
export const ConnexionConstraints = ()=>{

    const connexion :() => { username: string; Password: string } = () => {
        return (
            {
                username:"",
                Password:"",
                
            }
        )
    }
    const constraintData =()=>{
        return({
            messageCodeWrong:"",
            secondFace:false,
            isAdmin:false,
            permissions:{},
            loadingConnexionCode:false,
            loadingConnexion:false,
            connexionError:false,
            isRememberMe:false,
            message:"",
            thumbnail:"",
            username:"",
            firstName:"",
            lastName:"",
            id:"",
            
        })
    }

    return({
        connexion,
        constraintData,
    })

}