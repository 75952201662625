const DashboardIntegrated = ({language , token}) => {
  const newDashboardIframe = "https:" +
  window.process.env.URL_Dashboard
  +  "?lang=" + language + "&token=" +
    token

    const translaterRouter = {
      "podcast/list" : "/media/podcast",
      "medias/videos" : "/media/video",
      "playlist/list/simple" : "/media/playlist",
      "lives/list" : "/media/lives"
    }
    
    window.addEventListener('message', function(event) {
      if (event.data.event === 'changeRoute') {
        const parentUrl = window.parent.location.href;
        let newUrl;
        if(parentUrl.includes("/dashboard")){
          newUrl = parentUrl.replace('/dashboard', translaterRouter[event.data.data]);
        }else{
          newUrl = parentUrl.concat(translaterRouter[event.data.data].slice(1));
        }
        window.parent.location.href = newUrl;
      }
    }, false);

  return (
    <iframe
      id="iframe_dashboard_display"
      src={newDashboardIframe}
      allowFullScreen
      frameBorder={"0"}
      style={{ width: '100%', height: '98%', overflow: 'hidden' }}
    />
  );
};

export default DashboardIntegrated