import {DamConstantsData} from './constants'
import {IActions} from "../../schemas/types";

const {DamConstraintsData} = DamConstantsData()

const INITIALSTATE = {
    damConstraintsData:DamConstraintsData(),
}


export const reducer = (state=INITIALSTATE , action:any)=>{

    switch (action.type){

        case "SET_Actions" :
            const {damConstraintsDataNameChange,damConstraintsDataValueChange}=action.payload
            return {
                ...state,
                damConstraintsData:{...state.damConstraintsData, [damConstraintsDataNameChange]:damConstraintsDataValueChange}
            }
        default:{
            return state
        }

    }

}
