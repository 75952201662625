const ProfileUserIntegrated = ({language , token}) => {
  const newProfileUserIframe = "https:" +
  window.process.env.URL_Profile_User
  +  "?lang=" + language + "&token=" +
    token
  return (
    <iframe
      id="iframe_profileUser_display"
      src={newProfileUserIframe}
      allowFullScreen
      frameBorder={"0"}
      style={{ width: '100%', height: '98%', overflow: 'hidden' }}
    />
  )
}

export default ProfileUserIntegrated