const AbonnementUserIntegrated = ({language , token}) => {
    const newAbonnementUserIframe = "https:" +
    window.process.env.URL_Abonnement_User
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_abonnement_user_display"
        src={newAbonnementUserIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default AbonnementUserIntegrated