import React,{useState} from 'react'
import DashboardPart from './DashboardPart/DashboardPart'
import LayoutPart from './LayoutPart/LayoutPart'
import Divider from "@mui/material/Divider";
import CloseIcon from '@mui/icons-material/Close';
import "./OptionsProfile.scss"
import PaletteIcon from '@mui/icons-material/Palette';
import Layout1 from "../../../assets/1.png";
import Layout2 from "../../../assets/2.png";
import Layout3 from "../../../assets/3.png";
import Layout4 from "../../../assets/4.png";
import Layout5 from "../../../assets/5.png";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { GlobalHooks } from '../../../utils/Hooks';
import { useTranslation } from "react-i18next";
import { Check } from '@material-ui/icons';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
export const OptionsProfile = () => {
  const [themeOpen,setThemeOpen] = useState(false)
  const {cookies,setCookie} = GlobalHooks()
  const {t} = useTranslation()
  return (
    <>
    <div className={`Option-Affichage ${cookies.LayoutTheme === "L4" ? "setUpTheWith":""} `}>
        <LayoutPart setThemeOpen={setThemeOpen}/>
        <DashboardPart/>
    </div>
        {themeOpen ? (
          <div className='themeOptionsGlobal'>
            <div onClick={()=>setThemeOpen(false)} className='darkSpaceOptions'>
            </div>
              <div style={{height : cookies.LayoutTheme === "L2" ? "calc(100vh - 134px)" : cookies.LayoutTheme === "L4" ? "100vh" : "calc(100vh - 84px)"}} className='themeBarOptions'>
                <CloseIcon onClick={()=>setThemeOpen(false)} className='close' />
                <div className='part1'>
                  <div className='Blue-Title'>{t("OptionAffichage.Style de mise en page")} </div>
                  <div className='ThemeLayoutSelectionPart'>
                    <div style={{border: cookies.LayoutTheme === "default" ? `2px solid ${cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84"}` : "none"}} className='imgLayout'>
                      <input onClick={(e)=>{setCookie("LayoutTheme",e.target.value,{path:"/"})}} value={"default"} type='image' src={Layout1} alt="" />  
                      {cookies.LayoutTheme === "default" ? (
                          <CheckCircleRoundedIcon sx={{color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" ,position:"absolute",top:"0px",right:"0px"}} className='checkedLayout'/>
                        ):(<></>)}
                    </div>
                    <div style={{border: cookies.LayoutTheme === "L1" ? `2px solid ${cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84"}` : "none" }} className='imgLayout'>
                      <input onClick={(e)=>{setCookie("LayoutTheme",e.target.value,{path:"/"})}} value={"L1"} type='image' src={Layout2} alt="" />
                      {cookies.LayoutTheme === "L1" ? (
                          <CheckCircleRoundedIcon sx={{color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" ,position:"absolute",top:"0px",right:"0px"}} className='checkedLayout'/>
                        ):(<></>)}
                    </div>
                    <div style={{border: cookies.LayoutTheme === "L2" ? `2px solid ${cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84"}` : "none" }}  className='imgLayout'>
                      <input onClick={(e)=>{setCookie("LayoutTheme",e.target.value,{path:"/"})}} value={"L2"} type='image' src={Layout3} alt="" />
                      {cookies.LayoutTheme === "L2" ? (
                          <CheckCircleRoundedIcon sx={{color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" ,position:"absolute",top:"0px",right:"0px"}} className='checkedLayout'/>
                        ):(<></>)}
                    </div>
                    <div style={{border: cookies.LayoutTheme === "L3" ? `2px solid ${cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84"}` : "none" }} className='imgLayout'>
                      <input onClick={(e)=>{setCookie("LayoutTheme",e.target.value,{path:"/"})}} value={"L3"} type='image' src={Layout4} alt="" />
                      {cookies.LayoutTheme === "L3" ? (
                          <CheckCircleRoundedIcon sx={{color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" ,position:"absolute",top:"0px",right:"0px"}} className='checkedLayout'/>
                        ):(<></>)}
                    </div>
                    <div style={{border: cookies.LayoutTheme === "L4" ? `2px solid ${cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84"}` : "none" }}  className='imgLayout'>
                      <input onClick={(e)=>{setCookie("LayoutTheme",e.target.value,{path:"/"})}} value={"L4"} type='image' src={Layout5} alt="" />
                      {cookies.LayoutTheme === "L4" ? (
                          <CheckCircleRoundedIcon sx={{color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" ,position:"absolute",top:"0px",right:"0px"}} className='checkedLayout'/>
                        ):(<></>)}
                    </div>
                  </div>
                </div>
                <Divider sx={{ color: "white" , marginTop:"1rem" }} />
                <div className='part2'>
                  <div className='Blue-Title'>{t("OptionAffichage.Mode de mise en page")} </div>
                  <div className='sizedOption'>
                    <label>
                      <input checked={cookies.MiseEnPage === "boxed"} onChange={(e)=>setCookie("MiseEnPage",e.target.value,{path:"/"})} className="space-for-radios-table-length" type="radio" name="boxed" value="boxed" />
                     { t("OptionAffichage.Boxed")}
                    </label>
                    <label>
                      <input checked={cookies.MiseEnPage === "wide"} onChange={(e)=>setCookie("MiseEnPage",e.target.value,{path:"/"})} className="space-for-radios-table-length" type="radio" name="wide" value="wide" />
                     { t("OptionAffichage.Wide")}
                    </label>
                  </div>
                </div>
                <Divider sx={{ color: "white" , marginTop:"1rem" }} />
                <div className='part3'>
                  <div className='Blue-Title'>{t("OptionAffichage.couleur de mise en page")} </div>
                  <div className='ColorsSets'>
                    <div className='c1'>
                      <div onClick={()=>setCookie("ColorTheme","default",{path:"/"})} className='boxing'>
                        {cookies.ColorTheme === "default" ? (
                          <CheckCircleRoundedIcon className='checked'/>
                        ):(<></>)}
                        <div className='topBoxColorPalette'>
                          <PaletteIcon className='paletteColor'/>
                        </div>
                        <div className='bottomBoxColorExpample'></div>
                      </div>
                    </div>
                    <div className='c2'>
                      <div onClick={()=>setCookie("ColorTheme","c2",{path:"/"})} className='boxing'>
                      {cookies.ColorTheme === "c2" ? (
                          <CheckCircleRoundedIcon className='checked'/>
                        ):(<></>)}
                        <div className='topBoxColorPalette'>
                          <PaletteIcon className='paletteColor'/>
                        </div>
                        <div className='bottomBoxColorExpample'></div>
                      </div>
                    </div>
                    <div className='c3'>
                      <div onClick={()=>setCookie("ColorTheme","c3",{path:"/"})} className='boxing'>
                        {cookies.ColorTheme === "c3" ? (
                          <CheckCircleRoundedIcon className='checked'/>
                        ):(<></>)}
                        <div className='topBoxColorPalette'>
                          <PaletteIcon className='paletteColor'/>
                        </div>
                        <div className='bottomBoxColorExpample'></div>
                      </div>
                    </div>
                    <div className='c4'>
                      <div onClick={()=>setCookie("ColorTheme","c4",{path:"/"})} className='boxing'>
                        {cookies.ColorTheme === "c4" ? (
                          <CheckCircleRoundedIcon className='checked'/>
                        ):(<></>)}
                        <div className='topBoxColorPalette'>
                          <PaletteIcon className='paletteColor'/>
                        </div>
                        <div className='bottomBoxColorExpample'></div>
                      </div>
                    </div>
                    <div className='c5'>
                      <div onClick={()=>setCookie("ColorTheme","c5",{path:"/"})} className='boxing'>
                        {cookies.ColorTheme === "c5" ? (
                          <CheckCircleRoundedIcon className='checked'/>
                        ):(<></>)}
                        <div className='topBoxColorPalette'>
                          <PaletteIcon className='paletteColor'/>
                        </div>
                        <div className='bottomBoxColorExpample'></div>
                      </div>
                    </div>
                    <div className='c6'>
                      <div onClick={()=>setCookie("ColorTheme","c6",{path:"/"})} className='boxing'>
                        {cookies.ColorTheme === "c6" ? (
                          <CheckCircleRoundedIcon className='checked'/>
                        ):(<></>)}
                        <div className='topBoxColorPalette'>
                          <PaletteIcon className='paletteColor'/>
                        </div>
                        <div className='bottomBoxColorExpample'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        ):(<></>)}
        </>
  )
}
