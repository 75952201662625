import React, {useEffect} from 'react';
import { Router , Switch , Redirect , Route , BrowserRouter } from 'react-router-dom';

import MainLayout from './layout/MainLayout';
import { useHistory } from "react-router-dom";
import { useSelector} from "react-redux";
import './index.scss';
import {useAppSelector} from "./redux/hooks";
import Error from "./layout/components/Error";
import Connexion from "./connexion/components/Connexion";
import {ForgetPassword} from "./forgetPassword/forgetPassword";
import {ResetPassword} from "./resetPassword/resetPassword";
import { GlobalHooks } from './utils/Hooks';
import i18next from 'i18next';
import DoubleAuth from './layout/components/DoubleAuth/DoubleAuth';
import {GraphQLFetchData} from "./connexion/utils/graphQLFetchData"

function App() {
    const secondFace = useAppSelector((state) => state.ConnexionReducer.constraintData.secondFace)
    const {cookies,setCookie} = GlobalHooks()
    useEffect(()=> {
        if(cookies?.language?.length < 1){
            setCookie('language','fr',{ path: '/' })
        }else{
            i18next.changeLanguage(cookies.language)
        }
    },[i18next.language])
    
  return (
              <BrowserRouter>
                  <Switch>
                      <Route exact path='/forgot-password'> {!cookies.validated ? <ForgetPassword/> :
                          <Connexion/>}</Route>
                      <Route exact path='/reset-password'> {!cookies.validated ? <ResetPassword/> :
                          <Connexion/>}</Route>
                      {
                          !cookies.validated
                              ? 
                              !secondFace ? 
                                <Connexion/>
                              :
                                <DoubleAuth/>
                              : 
                              <MainLayout/>
                      } 
                  </Switch> 
              </BrowserRouter>
  );
}

export default App;
