import { useCookies } from 'react-cookie';

export const GlobalHooks = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['logo','redic','language','authToken','thumbnail','validated','username','id',"ColorTheme","LayoutTheme","ModeAffichage","GridTaille","DashboardView","MiseEnPage"]);
    return {
        cookies,
        setCookie,
        removeCookie
      };
    };
    