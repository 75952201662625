import books from './Books';
import "../index.scss"
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { GlobalHooks } from '../utils/Hooks';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
function BootstrapDialogTitle(props:DialogTitleProps) {
    const {cookies} = GlobalHooks()
    const { children, onClose, ...other } = props;
    
    return (
      <DialogTitle sx={{display:"flex",alignItems:"center",justifyItems:"center",gap:"5px",backgroundColor:cookies.ColorTheme === "default" ? "#F8FBFF" : cookies.ColorTheme === "c2" ? "#cee3e7" : cookies.ColorTheme === "c3" ? "#426c7a" : cookies.ColorTheme === "c4" ? "#8499e4" : cookies.ColorTheme === "c5" ? "#11776c" : "#aaaaaa" ,color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84", m: 0, p: 2 }} >
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
const BooksSwitcher = ({url,setOpenTheBook,t}:any) => {
  return (
    <>
      {" "}
      {books.map((book) => {
        if(url === book.path){
            return (
                <div className="BookGuideComponent">
                    <BootstrapDialog  
                        onClose={()=>setOpenTheBook(false)}
                        aria-labelledby="customized-dialog-title"
                        open={true}
                    >
                        <BootstrapDialogTitle onClose={()=>setOpenTheBook(false)}  id="customized-dialog-title" >
                           <HelpIcon sx={{color : Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"}} /> {t("Books.help")} {t(`Books.${book.title}`)}
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <book.component />
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{backgroundColor:Cookies.get('ColorTheme') === "default" ? "#F8FBFF" : Cookies.get('ColorTheme') === "c2" ? "#cee3e7" : Cookies.get('ColorTheme') === "c3" ? "#426c7a" : Cookies.get('ColorTheme') === "c4" ? "#8499e4" : Cookies.get('ColorTheme') === "c5" ? "#11776c" : "#aaaaaa" ,color : Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"}} autoFocus onClick={()=>setOpenTheBook(false)}>
                                {t("Books.close")}
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>  
                </div>
            );
        }
      })}
    </>
  )
}

export default BooksSwitcher