

//******************** connexion actions***************//

        export function setForgetPasswordOnchange(e:any){
            return{
                type: "SET_ForgetPasswordOnchange",
                payload:e,
            }
        }

//*******************Constraint Data actions*******************//

        export function setForgetPasswordConstraintDataOnchange(e:any){
            return{
                type:"SET_ForgetPasswordConstraintDataOnchange",
                payload:e
            }
        }








