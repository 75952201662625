const MoocIntegrated = ({language , token}) => {
    const newMoocIframe = "https:" +
    window.process.env.URL_Mooc
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_mooc_display"
        src={newMoocIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default MoocIntegrated