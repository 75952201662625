
import {UserConstraints} from "./persistConstants";
import {IActions} from "../../schemas/types";

const {UserConstantsData} = UserConstraints();


const persistInitialState = {

   userConstantsData : UserConstantsData()

}

export const  PersistReducer=(state=persistInitialState , action:any)=>{

    switch (action.type){

        //******** connexion reducer case************//
        case "SET_PersistActions":
            const {persistNameChange,persistValueChange}=action.payload
            const ConnexionOnOnchangeObj = {...state.userConstantsData,[persistNameChange]: persistValueChange}
            return{
                ...state,
                userConstantsData:ConnexionOnOnchangeObj
            }
        default:{
            return state
        }

    }

}