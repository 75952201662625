import React , {useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {graphQL_shema} from "./graphQL";
import {Hooks} from "./hooks";
import {useHistory } from "react-router-dom";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../redux/hooks";
import {setConnexionConstraintDataOnchange , setAppSetLogin , setAppSetLogout,setConnexionOnchange} from "../store/connexionActions";
import { message } from 'antd';
import { GlobalHooks } from "../../utils/Hooks";
import {  PostData } from "../../APIcalls";

export const GraphQLFetchData=()=> {
    const {cookies,setCookie} = GlobalHooks()
    // const [verificationToken, setVerificationToken] = useState<boolean>(false);
    // const token = localStorage.getItem('jwtToken')?localStorage.getItem('jwtToken'):'';
    const history = useHistory()
    const dispatch = useDispatch()
    const values = useAppSelector((state:any) => state.ConnexionReducer.connexion)

    const codeConnexion = async (code:string) =>{
        dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexionCode",constraintDataValueChange:true}))
        await PostData("/profiles/user/check-validation-code",{code:code,id:cookies.id})
        .then(res=>{
            if(res.data.code === 200){
                setCookie('authToken', res.data.token,{ path: '/' });
                setCookie('validated', true ,{ path: '/' });
                dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexionCode",constraintDataValueChange:false}))
                // history.push("/dashboard")
            }
            if(res.data.code === 400){
            dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"messageCodeWrong",constraintDataValueChange:"Your Code Is Wrong"}))
            }
        }).catch(err=>{
            dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexionCode",constraintDataValueChange:false}))
            dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"messageCodeWrong",constraintDataValueChange:"Your Code Is Wrong"}))
    }) 
    }

    const Connexion = async () =>{
        await PostData("/profiles/user/check-user",{username:values.username})
        .then(async (res:any)=>{
            if(res.data.code === 200){
                await PostData("/login",{password:values.Password,token_auth:res.data.token_auth})
                .then(async (data:any)=>{
                    if (data.data.code === 200) {
                                    if(data.data.isDoubleAuthentication == true){
                                        dispatch(setConnexionConstraintDataOnchange({
                                            constraintDataNameChange: "secondFace",
                                            constraintDataValueChange: true 
                                        }));
                                        dispatch(setAppSetLogin(data.data.token));
                                        setCookie('authToken', data.data.token,{ path: '/' });
                                        setCookie('thumbnail',  data.data.userData.user.vignette,{ path: '/' });
                                        setCookie('username', data.data.userData.user.username ,{ path: '/' });
                                        setCookie('id', data.data.userData.user.id ,{ path: '/' });
                                        setCookie('ColorTheme', "default" ,{ path: '/' });
                                        setCookie('LayoutTheme', "default" ,{ path: '/' });
                                        setCookie('MiseEnPage', "wide" ,{ path: '/' });
                                        setCookie('ModeAffichage', "moyenne" ,{ path: '/' });
                                        setCookie('GridTaille', "10" ,{ path: '/' });
                                        setCookie('redic', "" ,{ path: '/' });
                                        setCookie('DashboardView', "none" ,{ path: '/' });
                                        setCookie('logo', "logo" ,{ path: '/' });

                                        await PostData("/profiles/user/generate-validation-code",{email:data.data.userData.user.email,id:data.data.userData.user.id})
                                    }else{
                                        dispatch(setAppSetLogin(data.data.token));
                                        setCookie('authToken', data.data.token,{ path: '/' });
                                        setCookie('thumbnail',  data.data.userData.user.vignette,{ path: '/' });
                                        setCookie('username', data.data.userData.user.username ,{ path: '/' });
                                        setCookie('id', data.data.userData.user.id ,{ path: '/' });
                                        setCookie('ColorTheme', "default" ,{ path: '/' });
                                        setCookie('LayoutTheme', "default" ,{ path: '/' });
                                        setCookie('MiseEnPage', "wide" ,{ path: '/' });
                                        setCookie('ModeAffichage', "moyenne" ,{ path: '/' });
                                        setCookie('GridTaille', "10" ,{ path: '/' });
                                        setCookie('redic', "" ,{ path: '/' });
                                        setCookie('DashboardView', "none" ,{ path: '/' });
                                        setCookie('logo', "logo" ,{ path: '/' });
                                        dispatch(setConnexionConstraintDataOnchange({
                                            constraintDataNameChange: "secondFace",
                                            constraintDataValueChange: false 
                                        }));
                                        setCookie('validated', true ,{ path: '/' });
                                        // history.push("/dashboard")
                                        
                                    }
                                    dispatch(setConnexionConstraintDataOnchange({
                                        constraintDataNameChange: "id",
                                        constraintDataValueChange: data.data.userData.user.id 
                                    }));
                                    dispatch(setConnexionConstraintDataOnchange({
                                        constraintDataNameChange: "username",
                                        constraintDataValueChange: data.data.userData.user.username
                                    }));
                                    dispatch(setConnexionConstraintDataOnchange({
                                        constraintDataNameChange: "thumbnail",
                                        constraintDataValueChange: data.data.userData.user.vignette
                                    }));
                                    dispatch(setConnexionConstraintDataOnchange({
                                        constraintDataNameChange: "firstName",
                                        constraintDataValueChange: data.data.userData.user.firstName
                                    }));
                                    dispatch(setConnexionConstraintDataOnchange({
                                        constraintDataNameChange: "lastName",
                                        constraintDataValueChange: data.data.userData.user.lastName
                                    }));
                                    dispatch(setConnexionConstraintDataOnchange({
                                        constraintDataNameChange:"message",
                                        constraintDataValueChange:"user logged in"
                                    }))
                                    // history.push("/dashboard")
                                    
                    
                                } 
                                else if(data.code === 401) {
                                    dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))
                                    dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:data.data.message}))
                                }
                                
                    
                                dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))            
                })
                .catch((err)=>{
                    dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))
                    dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:"Password Wrong"}))
                })
            }
        }).catch(err=>{
            dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))
            dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:"user dont exist "}))
        })

    }


    // const [Connexion] = useMutation(graphQL_shema().Connexion, {
    //     context: {
    //         headers: {
    //         }
    //     },
    //     variables: {input : {...values}},
    //     fetchPolicy: "no-cache",
    //     onCompleted: async (data) => {
    //         if (data.login.status === 200) {
    //             history.push("/dashboard")
    //             dispatch(setAppSetLogin(data.login.Token));
    //             setCookie('authToken', data.login.Token,{ path: '/' });
    //             setCookie('thumbnail',  data.login.thumbnail,{ path: '/' });
    //             setCookie('validated', true ,{ path: '/' });
    //             setCookie('username', data.login.username ,{ path: '/' });
    //             setCookie('id', data.login.id ,{ path: '/' });
    //             setCookie('ColorTheme', "default" ,{ path: '/' });
    //             setCookie('LayoutTheme', "default" ,{ path: '/' });
    //             setCookie('MiseEnPage', "wide" ,{ path: '/' });
    //             setCookie('ModeAffichage', "moyenne" ,{ path: '/' });
    //             setCookie('GridTaille', "10" ,{ path: '/' });
    //             setCookie('DashboardView', "none" ,{ path: '/' });


    //             dispatch(setConnexionConstraintDataOnchange({
    //                 constraintDataNameChange: "id",
    //                 constraintDataValueChange: data.login.id
    //             }));
    //             dispatch(setConnexionConstraintDataOnchange({
    //                 constraintDataNameChange: "username",
    //                 constraintDataValueChange: data.login.username
    //             }));
    //             dispatch(setConnexionConstraintDataOnchange({
    //                 constraintDataNameChange: "thumbnail",
    //                 constraintDataValueChange: data.login.thumbnail
    //             }));
    //             dispatch(setConnexionConstraintDataOnchange({
    //                 constraintDataNameChange: "firstName",
    //                 constraintDataValueChange: data.login.firstName
    //             }));
    //             dispatch(setConnexionConstraintDataOnchange({
    //                 constraintDataNameChange: "lastName",
    //                 constraintDataValueChange: data.login.lastName
    //             }));
    //             dispatch(setConnexionConstraintDataOnchange({
    //                 constraintDataNameChange:"message",
    //                 constraintDataValueChange:data.login.Message
    //             }))
                

    //         } 
    //         else if(data.login.status === 400) {
    //             dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))
    //             dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:data.login.Message}))
    //         }
            
            
    //         else if (data.login.status === 500) {

    //             message.error('This is an error message');
    //             dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:true}))
    //             dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:""}))


    //         }

    //         dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))

    //     },
    //     onError(err) {
    //         dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))
    //         dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:"Verify Your credentials"}))
            
    //     }
        
    // });


    // const [tokenAPI]=useMutation(graphQL_shema().tokenVerification, {
    //     context: {
    //         headers: {
    //         }
    //     },
    //     fetchPolicy:  "no-cache",
    //     variables: { input :{token: `Bearer ${token}`}},
    //     onCompleted :async (data)=>{
    //         if (data.VerifSecureTokenFoAction.Code===200)
    //         {
    //             dispatch(setAppSetLogin(token));
    //         }else{
    //             dispatch(setAppSetLogout(''));
    //         }
    //         setVerificationToken(true)
    //     },
    //     onError(err) {
    //     }
    // })

    return({
        Connexion,
        // verificationToken,
        // tokenAPI
        codeConnexion
    })

}