

//******************** connexion actions***************//

        export function setResetPasswordOnchange(e:any){

            return{
                type: "SET_ResetPasswordOnchange",
                payload:e,
            }
        }

//*******************Constraint Data actions*******************//

        export function setResetPasswordConstraintDataOnchange(e:any){
            return{
                type:"SET_ResetPasswordConstraintDataOnchange",
                payload:e
            }
        }








