import { useEffect } from "react";
import { Hooks } from "../layout/utils/hooks";
import { useAppSelector } from "../redux/hooks";
import { GlobalHooks } from "../utils/Hooks";

const StatsExportsIntegrated = ({language , token}) => {
  const exportToken = useAppSelector((state) => state.LayoutReducer.STAT_EXPORT_URL)
  const {cookies} = GlobalHooks()
  const {getURLSTATExport} = Hooks()

  const OPTIONS = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Authorization" : "Bearer "+ cookies.authToken,
    },
  };

  useEffect(()=>{
    getURLSTATExport(OPTIONS)
  },[])

  const newStatsExportsIframe = "https:" +
    window.process.env.URL_StatsExports
    +  "?lng=" + language + "&token=" +
    exportToken.split("/").at(-1)
  
    return (
      <iframe
        id="iframe_statsExports_display"
        src={newStatsExportsIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default StatsExportsIntegrated