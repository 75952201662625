import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Select from "@mui/material/Select";
import "./NavBar.css";
import fr from "../../../assets/fr.png";
import en from "../../../assets/en.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Hooks } from "../../utils/hooks";
import { GlobalHooks } from "../../../utils/Hooks"
import { useAppSelector } from "../../../redux/hooks";
import {useWindowSize} from "../../utils/resize"
import Logo from "../../../assets/logo_dam.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import HasPermission from "../HasPermission/HasPermission";
import HelpIcon from '@mui/icons-material/Help';
import books from "../../../router/Books";
const Navbar = ({ open, handleOnCollapse, setOpenTheBook }) => {
  const permissions = useAppSelector((state) => state.ConnexionReducer.constraintData.permissions)
  const isAdmin = useAppSelector((state) => state.ConnexionReducer.constraintData.isAdmin)
  const {cookies,setCookie} = GlobalHooks()
  const { t } = useTranslation();
  const { search } = useLocation();
  const [wid,setWid] = useState()
  const obj = new URLSearchParams(search);
  const history = useHistory();
  const {handleLogout} = Hooks()
  const {thumbnail,username} = useAppSelector(state=>state.ConnexionReducer.connexion)
  const userData = useAppSelector((state) => state.ProfileReducer.profileConstraintsData)
 
  const changeLanguage = (e) => {
    i18next.changeLanguage(e);
    setCookie("language",e,{path:"/"})
        if(document.getElementById('iframe_dashboardStats_display'))
        {
          document.getElementById('iframe_dashboardStats_display').contentWindow.postMessage({
            info: "updateLang",
            lang:e
          }, "*");
        }
        if(document.getElementById('iframe_statsDirect_display'))
        {
          document.getElementById('iframe_statsDirect_display').contentWindow.postMessage({
            info: "updateLang",
            lang:e
          }, "*");
        }
    
        if(document.getElementById('iframe_statsVideos_display'))
        {
          document.getElementById('iframe_statsVideos_display').contentWindow.postMessage({
            info: "updateLang",
            lang:e
          }, "*");
        }
        if(document.getElementById('iframe_statsAudit_display'))
        {
          document.getElementById('iframe_statsAudit_display').contentWindow.postMessage({
            info: "updateLang",
            lang:e
          }, "*");
        }
        if(document.getElementById('iframe_statsExports_display'))
        {
          document.getElementById('iframe_statsExports_display').contentWindow.postMessage({
            info: "updateLang",
            lang:e
          }, "*");
        }
  };
  const hide = useWindowSize()

  useEffect(()=>{
    setWid(hide > 960)
  },[hide])

  const openLinkInNewWindow = () => {
    window.open("https://support.empreinte.com/docs/bo/html/index.html", "_blank");
  }

  return (
    <div className={`navbar-container ${(cookies.LayoutTheme !== "L3" && cookies.LayoutTheme !== "L2" && cookies.LayoutTheme !== "L4") ? open ? "secondeLayoutModif" : "secondeLayoutModifUndo" : ""} `}>
      <AppBar position="static" sx={{ backgroundColor: cookies.ColorTheme === "default" ? "#F8FBFF" : cookies.ColorTheme === "c2" ? "#cee3e7" : cookies.ColorTheme === "c3" ? "#426c7a" : cookies.ColorTheme === "c4" ? "#8499e4" : cookies.ColorTheme === "c5" ? "#11776c" : "#aaaaaa", boxShadow:"none"}}>
        <Toolbar className="gg">
          {(cookies.LayoutTheme === "L1" || cookies.LayoutTheme === "L3" || cookies.LayoutTheme === "L2")  && (
            <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              height: 64,
              paddingRight: "3px",
            }}
            >
                <img src={Logo} alt="Logo" height={40} />
                {cookies.LayoutTheme !== "L3" && cookies.LayoutTheme !== "L2" && 
                (open ? (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{
                      color: "black",
                      width: "45px",
                      height: "70px",
                      marginLeft: "auto",
                    }}
                    onClick={handleOnCollapse}
                    >
                    <MenuOpenIcon />
                  </IconButton>
                ) : (
                  <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    color: "black",
                    width: "80px",
                    height: "70px",
                  }}
                  onClick={handleOnCollapse}
                  >
                    <MenuIcon />
                  </IconButton>
                ))}
                
            </Box> 
          )}
            
          <div className="right-navBar">
            <Box className="user-dropdown" >
              <Select
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                style={{
                  width: "100%",
                  height: "64px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
                  borderRadius: "0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderColor:"#F8FBFF",
                  border:"#F8FBFF",
                  
                }}
                notched={false}
                value={"default"}
                IconComponent={() => null}
              >
                <MenuItem
                  value="default"
                  sx={{ width: "100%", gap: "10px", display: "none" }}
                >
                  <div className="select-languagee">
                    <Avatar alt="User Name" sx={{border: `3px dashed ${cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84"} `}} src={userData?.Vignette ? userData?.Vignette : cookies.thumbnail} />
                    {wid && (
                      <span className="titlesOfP">{username ? username : localStorage.getItem("storageUsername") ? localStorage.getItem("storageUsername") : cookies.username }</span>
                    )}
                  </div>
                </MenuItem>
                <HasPermission nav={true} permission={"profiles_user_get_detail"}  allowed={!(((permissions?.userData?.modules["profiles_user_get_detail"]) !== true) && (!isAdmin) )} >
                  <MenuItem
                    value={"Profile"}
                    sx={{ width: "100%", gap: "10px"}}
                    onClick={() => {
                      history.push("/profile/user/profilconnected");
                    }}
                  >
                    <AccountCircleIcon />
                    <span>{t("ProfileNav")}</span>
                  </MenuItem>
                </HasPermission>
                <HasPermission nav={true} permission={"profiles_user_get_detail"}  allowed={!(((permissions?.userData?.modules["profiles_user_get_detail"]) !== true) && (!isAdmin) )} >
                  <Divider className="divider" />
                </HasPermission>
                <HasPermission nav={true} permission={"options_theme_key_no_api"}  allowed={!(((permissions?.userData?.modules["options_theme_key_no_api"]) !== true) && (!isAdmin) )} >
                  <MenuItem value={"Options"} sx={{ gap: "10px" }}
                    onClick={() => {
                      history.push("/configuration/display_options");
                    }}>
                    <SettingsIcon />
                    <span>{t("OptionsNav")}</span>
                  </MenuItem>
                </HasPermission>
                <HasPermission nav={true} permission={"support_key_no_api"}  allowed={!(((permissions?.userData?.modules["support_key_no_api"]) !== true) && (!isAdmin) )} >
                  <MenuItem value={"API Keys"} sx={{ gap: "10px" }} 
                    onClick={() => {
                      history.push("/support/Api/Key");
                    }}>
                    <VpnKeyIcon />
                    <span>{t("API Keys")}</span>
                  </MenuItem>
                </HasPermission>
                <HasPermission nav={true} permission={"support_documentation_no_api"}  allowed={!(((permissions?.userData?.modules["support_documentation_no_api"]) !== true) && (!isAdmin) )} >
                  <Divider className="divider" />
                </HasPermission>
                <HasPermission nav={true} permission={"support_documentation_no_api"}  allowed={!(((permissions?.userData?.modules["support_documentation_no_api"]) !== true) && (!isAdmin) )} >
                  <MenuItem value={"User Guide"} sx={{ gap: "10px" }}>
                    <HelpIcon />
                    <span onClick={()=>openLinkInNewWindow()}>{t("User Guide")}</span>
                  </MenuItem>
                </HasPermission>

                <Divider className="divider" />
                <MenuItem value={"Logout"} sx={{ gap: "10px" }} onClick={handleLogout}>
                  <ExitToAppIcon />
                  <span>{t("Logout")}</span>
                </MenuItem>
              </Select>
            </Box>
            <Box className="language-dropdown">
            
              <Select
                sx={{ boxShadow: 'none',  '.MuiOutlinedInput-notchedOutline': { border: 0 }, }}
                style={{
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "0",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border:"none",
                }}
                value={i18next.language}
                onChange={(e) => changeLanguage(e.target.value)}
                IconComponent={() => null}
              >
                <MenuItem value={"fr"} >
                  <img style={{marginRight:"0.5rem"}} src={fr} alt="Français" />
                  FR
                </MenuItem>
                <MenuItem value={"en"} >
                  <img style={{marginRight:"0.5rem"}}  src={en} alt="English" />
                  EN
                </MenuItem>
              </Select>
              <div className="Languagee">{i18next.language.toLocaleUpperCase}</div>

            </Box>
          </div>
          {books.some(obj => window.location.pathname.includes(obj.path) ) && (
            <HelpIcon onClick={()=>setOpenTheBook(true)} sx={{cursor:"pointer" ,color : cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84"}} />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
