import React from 'react'
import "./LayoutPart.css"
import { GlobalHooks } from '../../../../utils/Hooks'
import { useTranslation } from "react-i18next";

const LayoutPart = ({setThemeOpen}) => {
  const {cookies} = GlobalHooks()
  const colorValue = cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" 
  const {t} = useTranslation()
  return (
    <div className='BorderLayoutOptions'>
        <div className='Blue-Title' style={{color:colorValue}} >{t("OptionAffichage.Layout")}</div>
        <div className='Layout-content'>
            <h4>{t("OptionAffichage.Changer le style, mode ou couleur de l'affichage")}</h4>
            <button onClick={()=>setThemeOpen(true)} className='change-layout-button' style={{backgroundColor:colorValue}}>{t("OptionAffichage.Changer Layout")}</button>
        </div>
    </div>
  )
}

export default LayoutPart