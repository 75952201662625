import React, { useEffect, useState } from "react";
import "../Connexion.scss";
import svg from "../../assets/Group.svg";
import logo from "../../assets/logo_dam.png";
import { Hooks } from "../utils/hooks";
import { useAppSelector } from "../../redux/hooks";
import { setConnexionOnchange } from "../store/connexionActions";
import { useDispatch } from "react-redux";
import {
  FormControl,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Typography,
  Checkbox,
} from "@mui/material";
import { AccountCircle, LockOutlined } from "@material-ui/icons";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useTranslation } from "react-i18next";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { GlobalHooks } from "../../utils/Hooks";
import Cookies from "js-cookie";

  import demo from "../../assets/clients/empreinte.png";
  import dassault from "../../assets/clients/dassault.png";
  import frogmedia from "../../assets/clients/frogmedia.png";
  // import "matele-ad" from "../../assets/clients/matele-ad.png";
  import safran from "../../assets/clients/safran.png";
  import safran_preprod from "../../assets/clients/safran_preprod.png";
  import cnam from "../../assets/clients/cnam.png";
  import gers from "../../assets/clients/gers.png";
  import minefi from "../../assets/clients/minefi.png";
  import cned from "../../assets/clients/cned.png"


  import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import fr from "../../assets/fr.png";
import en from "../../assets/en.png";
import CopyrightIcon from '@mui/icons-material/Copyright';
import i18next from "i18next";

  type ImageMap = {
    [key: string]: string | undefined;
  };
  
  const imageMap :ImageMap = {
    logo:logo,
    demo: demo,
    dassault: dassault,
    frogmedia: frogmedia,
    // "matele-ad": "matele-ad", // uncomment if available as an imported image
    safran: safran,
    safran_preprod: safran_preprod,
    cnam: cnam,
    gers: gers,
    minefi: minefi,
    cned: cned
  };

function Connexion() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visibelPassword, setVisiblePassword] = useState(false);
  const [verif, setVerif] = useState(false);
  const loading = useAppSelector(
    (state) => state.ConnexionReducer.constraintData
  );
  const {cookies,setCookie} = GlobalHooks()
  const userData = useAppSelector((state) => state.ConnexionReducer.connexion);
  //   const data = useAppSelector((state) => state.ConnexionReducer);
  //   const rememberMe = useAppSelector(
  //     (state) => state.PersistReducer.userConstantsData
  //   );
  const remember = localStorage.getItem("storageIsRememberMe");
  const username = localStorage.getItem("storageUsername");
  const password = localStorage.getItem("storagePassword");
  
  const {
    onFinish,
    onFinishFailed,
    connexionOnChange,
    form,
    connexionOnChangeButton,
    toForgotPassword,
  } = Hooks();


  const webtv_clients = ["demo", "dassault", "matele-ad","safran","safran_preprod","cnam","gers","minefi","cned"];
  // const webtv_client_images: { [key: string]: string } = {
  //   demo: "assets/clients/empreinte.png",
  //   dassault: "assets/clients/dassault.png",
  //   frogmedia: "assets/clients/frogmedia.png",
  //   "matele-ad": "assets/clients/matele-ad.png",
  //   safran: "assets/clients/safran.png",
  //   safran_preprod: "assets/clients/safran_preprod.png",
  //   cnam: "assets/clients/cnam.png",
  //   gers: "assets/clients/gers.png",
  //   minefi: "assets/clients/minefi.png",
  //   cned: "assets/clients/cned.png"
  // };

    webtv_clients.forEach((e) => {
      if (window.location.href.split('?')[0].indexOf(e) > -1) {
        setCookie("logo",e,{path:"/"})  
      }
    });


  useEffect(() => {
    form.setFieldsValue({
      isRememberMe: remember,
    });
    if (JSON.parse(remember as string) === true) {
      form.setFieldsValue({
        username: username,
        Password: password,
      });
      dispatch(
        setConnexionOnchange({
          ConnexionNameChange: "username",
          ConnexionValueChange: username,
        })
      );
      dispatch(
        setConnexionOnchange({
          ConnexionNameChange: "Password",
          ConnexionValueChange: password,
        })
      );
    } else {
      form.resetFields();
    }
  }, []);

  useEffect(() => {
    if ((userData.Password.length > 0 && userData.Password !== "undefined") && (userData.username.length > 0 && userData.username !== "undefined")) {
      setVerif(false);
    }
  }, [userData.Password, userData.username]);

  const changeLanguage = (e:any) => {
    i18next.changeLanguage(e);
    setCookie("language",e,{path:"/"})
  };

  const openLinkInNewWindow = () => {
    window.open("https://www.empreinte.com/mentions-legales/", "_blank");
  }
  // const logoCookie = Cookies.get("logo");
  // const imageSrc = imageMap[logoCookie] || null;

  return (
    <div className={"Connexion"}>
      <div className={"backgroundDiv"}>
        <img className={"icon-Group"} src={svg} />
      </div>
      <div className="formConnexion">
        <FormControl>
          <div className={"LogoConnexionDiv"}>
            <img src={(imageMap as {[key: string]: string | undefined})[Cookies.get("logo") ?? "logo"]} />
          </div>
          <div className={"TitleConnexionDiv"}>
            <span>Connexion</span>
          <Box className="language-dropdown">
            <Select
              sx={{ boxShadow: 'none',  '.MuiOutlinedInput-notchedOutline': { border: 0 }, }}
              style={{
                width: "100%",
                boxShadow: "none",
                borderRadius: "0",
                backgroundColor: "transparent",
                border:"none",
              }}
              value={i18next.language}
              onChange={(e) => changeLanguage(e.target.value)}
              IconComponent={() => null}
            >
              <MenuItem value={"fr"} >
                <img style={{marginRight:"0.5rem"}} src={fr} alt="Français" />
                FR
              </MenuItem>
              <MenuItem value={"en"} >
                <img style={{marginRight:"0.5rem"}}  src={en} alt="English" />
                EN
              </MenuItem>
            </Select>
            <div className="Languagee">{i18next.language.toLocaleUpperCase}</div>

          </Box>
          </div>
          <TextField
            placeholder={t("Nom d'utilisateur")}
            variant="outlined"
            name="username"
            defaultValue={JSON.parse(remember as string) === true ? username : ""}
            onChange={connexionOnChange}
            InputProps={{
              startAdornment: (
                <AccountCircle className="icon-Login-Dashboard" />
              ),
            }}
            fullWidth
            required
            sx={{ marginBottom: "1.5rem" }}
            error={
              verif &&
              (userData.username.length <= 0 ||
                userData.username === "undefined")
            }
            helperText={
              verif &&
              (userData.username.length <= 0 ||
                userData.username === "undefined")
                ? t("Please enter your username!")
                : ""
            }
          />
          <TextField
            type={!visibelPassword ? "password" : "text"}
            placeholder={t("Mot de passe")}
            variant="outlined"
            name="Password"
            defaultValue={JSON.parse(remember as string) === true ? password : ""}
            onChange={connexionOnChange}
            InputProps={{
              startAdornment: <LockOutlined className="icon-Login-Dashboard" />,
              endAdornment: (
                visibelPassword ? (
                  <VisibilityOffIcon
                    onClick={() => setVisiblePassword(false)}
                    className="Hide-Login-Dashboard"
                  />
                ) : (
                  <RemoveRedEyeIcon
                    onClick={() => setVisiblePassword(true)}
                    className="Hide-Login-Dashboard"
                  />
                )
              ),
            }}
            fullWidth
            required
            sx={{ marginBottom: "1.5rem" }}
            error={
              verif &&
              (userData.Password.length <= 0 ||
                userData.Password === "undefined")
            }
            helperText={
              verif &&
              (userData.Password.length <= 0 ||
                userData.Password === "undefined")
                ? t("Please enter your password!")
                : ""
            }
          />
          <Grid container className={"rememberMeDiv"}>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isRememberMe"
                    checked={JSON.parse(remember as string)}
                    onChange={connexionOnChangeButton}
                  />
                }
                label={t("Se souvenir de moi")}
              />
            </Grid>
            <Grid className={"rememberMeDivCol2"}>
              <Typography
                variant="body1"
                onClick={() => {
                  toForgotPassword();
                }}
              >
                {t("Mot de passe oublié")}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" className="error">
            {loading.message}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading.loadingConnexion}
            onClick={() => {
              if (!verif && (userData.Password.length > 0 && userData.Password !== "undefined") && (userData.username.length > 0 && userData.username !== "undefined")) {
                onFinish({username:userData.username,password:userData.Password});
              } else {
                setVerif(true);
              }
            }}
          >
            {loading.loadingConnexion ? t("Loading...") : t("Login")}
          </Button>
        </FormControl>
        <div className="underLoginButton">
          <span className="copyRight"><CopyrightIcon /> 2023 empreinte.com</span>
          <span className="anotherLinkEmpreient" onClick={()=>openLinkInNewWindow()}>{t("Legal Mentions")}</span>
        </div>
      </div>
    </div>
  );
}

export default Connexion;
