import gql from "graphql-tag";


    export const Get_User_Data = gql`
        mutation ($id:Int){
            User(id:$id){
                Id
                Username
                UsernameCanonical
                Email
                EmailCanonical
                Enabled
                Salt
                Password
                LastLogin
                ConfirmationToken
                PasswordRequestedAt
                Roles
                FirstName
                LastName
                ConnectionType
                Vignette
                Phone
                Mobile
                Description
                Adresse
                City
                Society
                PostalCode
                NumberPerson
                UserType
                TokenValidation
        
            }
        }
    `
    export const Update_User = gql`
        mutation ($input:UpdateUserInput!){
            updateUser(input:$input){
                Id
                Username
                UsernameCanonical
                Email
                EmailCanonical
                Enabled
                Salt
                Password
                LastLogin
                ConfirmationToken
                PasswordRequestedAt
                Roles
                FirstName
                LastName
                ConnectionType
                Vignette
                Phone
                Mobile
                Description
                Adresse
                City
                Society
                PostalCode
                NumberPerson
                UserType
                TokenValidation
                SubscriptionGroup{Id}
            }
      }`

      export const upload_File = gql`
        mutation($file: Upload!) {
            UploadToS3(file: $file) {
                code
                UrlFile
            }
        }
  `;




    
