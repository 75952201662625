import React from 'react';
import './forgetPassword.scss'
import {FormForgetPassword} from "./components/formForgetPassword";
import svg from "../assets/Group.svg";


export const ForgetPassword =()=>{
    return(
        <div className={"divGlobalForgetPassword"} style={{backgroundImage: `url(${svg})` , backgroundColor:"RGB(240, 243, 247)"}}>
            <FormForgetPassword/>
        </div>
    )
}