import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from './locales/fr/translation.json'
import en from './locales/en/translation.json'
import Cache from 'i18next-localstorage-cache';


const languageDetector = new LanguageDetector(null, {
    order: ['querystring', 'cookie', 'localstorage'],
    // lookupCookie: 'languagee', 
    caches: ['cookie'] 
  });
  
  languageDetector.detect = (callback) => {
    const detectedLng = languageDetector.cacheUserLanguage();
    let finalLng = detectedLng;
  
    if (detectedLng && detectedLng.includes('-')) {
      finalLng = detectedLng.split('-')[0];
    }
  
    if (typeof callback === 'function') {
      callback(finalLng);
    }
  };
  
  i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      react: {
        useSuspense: false 
      },
      fallbackLng: 'fr',
      debug: false,
      whitelist: ['en', 'fr'],
      resources: {
        fr: {
          common: fr
        },
        en: {
          common: en
        }
      },
      ns: ['common'],
      defaultNS: 'common',
      interpolation: {
        escapeValue: false
      }
    });
  
export default i18n;
