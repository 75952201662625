import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../redux/hooks";
import {setProfileUserActions} from "../store/profileActions";
import {GraphQLFetchData} from "./GraphFetchData"
export const Hooks = () =>{
    const {UpdateUser,UpdateUserVignette} = GraphQLFetchData()
    const [state, setState] = useState<boolean>(true)
    const [Once,setOnce] = useState(false)
    const dispatch = useDispatch()
    const [valid, setValid] = useState({
        Email : true ,
        FirstName : true ,
        LastName : true ,
        Phone : true ,
        Adresse : true ,
        City : true ,
        Society : true ,
        PostalCode : true ,
        NumberPerson : true ,
    }
    );
    

    const updateImage = (id:any,img:any) => {
        UpdateUserVignette({variables:{input:{Id:id,Vignette:img}}})
    }

    const updateUserDataProfileChange = (user:any)=>{
        UpdateUser({variables:{input:user}})
    }
    function removeLeadingZeros(input:any) {
      const inputStr = String(input);
    
      const trimmedStr = inputStr.replace(/^0+/, '');
    
      if (!isNaN(input)) {
        return Number(trimmedStr);
      }
    
      return trimmedStr;
    }
    const onChangeUserProfile = (name:any,value:any) =>{
      setOnce(true)
        if(name === "NumberPerson" || name === "Phone" || name === "PostalCode"){
            validateNumber(value, name)
          }else if (name === "Email"){
            validateEmail(value,name)
          }
          else{
            validateString(value, name)
          }
          if(name === "NumberPerson"){
            if(name === "NumberPerson" && value === ""){
              dispatch(setProfileUserActions({
                profileConstraintsDataNameChange: name,
                  profileConstraintsDataValueChange: 0
                }));
            }else{
              dispatch(setProfileUserActions({
                profileConstraintsDataNameChange: name,
                  profileConstraintsDataValueChange: removeLeadingZeros(value)
                }));
            }
          }else{
            dispatch(setProfileUserActions({
              profileConstraintsDataNameChange: name,
                profileConstraintsDataValueChange: value
              }));
          }
        }



    const onReset = () => {
        dispatch(
            ({ type: "SET_ProfileData", payload: {}})
        );
        window.history.back();
    };


    function validateString(input: any, assign: any) {
      if(assign !== "City" && assign !== "Society"){

        if (typeof input !== "string") {
          return setValid((prevState) => {
            return {
              ...prevState,
              [assign]: false,
            };
          });
        }
      }
        if(assign !== "City" && assign !== "Society"){
          if (input.trim().length === 0) {
            return setValid((prevState) => {
              return {
                ...prevState,
                [assign]: false,
              };
            });
          }
        }
    
        if (input.length > 100) {
          return setValid((prevState) => {
            return {
              ...prevState,
              [assign]: false,
            };
          });
        }
        
        if (/^\d+$/.test(input)) {
          return setValid((prevState) => {
            return {
              ...prevState,
              [assign]: false,
            };
          });
        } 
        else {
          return setValid((prevState) => {
            return {
              ...prevState,
              [assign]: true,
            };
          });
        }
      }

      const validateEmail = (input: string, assign: any) => {
        const emailRegex = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$', 'i');
        const isValid = emailRegex.test(input);
        setValid((prevState) => 
        ({
         ...prevState,
         [assign]: isValid,
       })
     );
      };

      function validateNumber(input: string, assign: any) {
        const inputAsString = input.toString();

        function isInputValid(inputAsString:string) {
          const regex = new RegExp("^[0-9 +]+$");
          const isValid = regex.test(inputAsString);
          if(assign === "Phone"){
            if (input.trim().length === 0) {
              return setValid((prevState) => {
                return {
                  ...prevState,
                  [assign]: false,
                };
              });
            }
          }
          if(assign === "NumberPerson" || assign === "PostalCode"){
            if (input.trim().length === 0) {
              return setValid((prevState) => {
                return {
                  ...prevState,
                  [assign]: true,
                };
              });
            }
          }
          setValid((prevState) => 
             ({
              ...prevState,
              [assign]: isValid,
            })
          );
        }
    
        isInputValid(inputAsString)
       

    }


    const values = useAppSelector((state:any) => state.ProfileReducer.profileConstraintsData)

    const handleChangeSelect = (value:any)=> {
        dispatch(setProfileUserActions({
            profileConstraintsDataNameChange: "numberPerson",
            profileConstraintsDataValueChange: value
        }));
    }
    const handleChangeInputs = (event:any) =>{
        dispatch(setProfileUserActions({
            profileConstraintsDataNameChange: event.target.name,
            profileConstraintsDataValueChange: event.target.value
        }));
        event.target.value === '' ? setState(true) : setState(false)
    }

    return({
        handleChangeSelect,
        onReset,
        handleChangeInputs,
        values,
        state,
        updateImage,
        onChangeUserProfile,
        updateUserDataProfileChange,
        valid,
        Once
    })
}