import axios from "axios";
import Cookies from 'js-cookie';
import axiosInstance from "./AxiosInstant";
const token = Cookies.get('authToken');
// const headers = {
//     Authorization: token ? `Bearer ${token}` :"",
// }


const url = window.process.env.API_HOST_GLOBAL;
export const PostData = async (path, data) => {
    return await axiosInstance().post(`${"https:" + url}${path}`, data);
};
export const GetData = async (path) => {
    return await axiosInstance().get(`${"https:" + url}${path}`);
};
export const GetDataPermissions = async (path,token) => {
    return await axiosInstance().get(`${"https:" + url}${path}`);
}
export const DeleteData = async (path) => {
    return await axiosInstance().delete(`${"https:" + url}${path}`);
};
export const UpdateData = async (path, data) => {
    return await axiosInstance().put(`${"https:" + url}${path}`, data);
};
export const upload = async (path, data) => {
    return await axiosInstance().post(`${"https:" + url}${path}`, data);
};
