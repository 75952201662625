import { useEffect } from "react";
import { Hooks } from "../layout/utils/hooks";
import { useAppSelector } from "../redux/hooks";
import { GlobalHooks } from "../utils/Hooks";

const LivesIntegrated = ({language , token}) => {
  const LIVE_URL = useAppSelector((state) => state.LayoutReducer.LIVE_URL)
  const {cookies} = GlobalHooks()
  const OPTIONS = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Authorization" : "Bearer "+ cookies.authToken,
    },
  
  };
  const {getURLLive} = Hooks()
  useEffect(()=>{
    getURLLive(OPTIONS)
  },[])


    const newLivesIframe = "https:" + LIVE_URL 
    // + "&lng=" + language
    // +  "?lang=" + language + "&token=" +
    //   token
  
    return (
      <iframe
        id="iframe_lives_display"
        src={newLivesIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default LivesIntegrated