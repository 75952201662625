const ClientIntegrated = ({language , token}) => {
    const newClientIframe = "https:" +
    window.process.env.URL_Client
    +  "?lang=" + language + "&token=" +
      token
  
    return (
      <iframe
        id="iframe_client_display"
        src={newClientIframe}
        allowFullScreen
        frameBorder={"0"}
        style={{ width: '100%', height: '98%', overflow: 'hidden' }}
      />
    );
  };
  
  export default ClientIntegrated