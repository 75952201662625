import React,{useEffect, useLayoutEffect, useState} from 'react'
import "./Profile.scss"
import Avatar from "@mui/material/Avatar";
import { useAppSelector } from "../../../redux/hooks";
import FileBase64 from "react-file-base64"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import TextField from '@mui/material/TextField';
import Textarea from '@mui/joy/Textarea';
import { FormControl } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {GraphQLFetchData} from "./utils/GraphFetchData"
import { Hooks } from './utils/hooks';
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { GlobalHooks } from '../../../utils/Hooks';

const Profile = () => {
  const {GetUserData,uploadFile} = GraphQLFetchData()
  const {cookies} = GlobalHooks()
  const {updateImage,onChangeUserProfile,onReset,updateUserDataProfileChange,valid,Once} = Hooks()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [images,setImages] = useState()
  const progress = 60
  const {id} = useAppSelector((state) => state.ConnexionReducer.constraintData)
  const newUserData = useAppSelector((state) => state.ProfileReducer.ProfileConstraintsDataUpdated)
  const oldUserData = useAppSelector((state) => state.ProfileReducer.profileConstraintsData)
  const show = useAppSelector((state) => state.ProfileReducer.ShowButton.showRegister)

  const onChange = (e) => {
    const file = e.file
    uploadFile({ variables:  { file } })
}

  useLayoutEffect(()=>{
    if(id || cookies.id){
      GetUserData({variables:{id:id ? id : cookies.id }})
    }
  },[id,cookies.id])

  function checkObjectCOntained(obj1, obj2) {
    for (let key in obj1) {
      if (!(key in obj2) || obj1[key] !== obj2[key]) {
          return false
        }
    }
return true;
  }
  
  useEffect(()=>{
    if(Once){
        if(checkObjectCOntained(newUserData,oldUserData)){
            dispatch({ type: "SET_divs_show", payload:false})
        }else{
            dispatch({ type: "SET_divs_show", payload:true})
        }
    }
  },[Once,newUserData])

  const checkVlidationInputs = () => {
    return Object.values(valid).every(e => e === true);
  };


  const updateUserVignette = () =>{
    if((images && newUserData.Vignette && id)){
        updateImage(id,newUserData.Vignette)
        setImages()
    }
    if((images && cookies.id && cookies.thumbnail)){
        updateImage(cookies.id,cookies.thumbnail)
        setImages()
    }
  }

  return (
    <div className='Profile_Container'>
        <div className='card1_2'>
            <div className='card1'>
                <div className='ProfileCardTitle'>{t("profilUser.profil")}</div>
                <hr className='underLineHeaderProfile'/>
                <div className='ProfileActionsAndIconAndUpload'>
                    <div className='ProfileDataAndActions'>
                        <Avatar alt="User Image" src={newUserData?.Vignette} sx={{ width: 55, height: 55 }} />
                        <div className='ProfileData'>
                            <div className='modifierPhotoDeProfile'>{t("profilUser.Modifier Votre Photo de profile")}</div>
                            <div className='actionProfile'>
                                <span onClick={()=>updateUserVignette()} className={`modifProfile ${images ? "Modif" : "Supp no" }`}>{t("profilUser.Modifier")}</span> 
                            </div>
                        </div>
                    </div>
                    <div className={`UploadImageProfile ${images ? "" : "animate" } `}>
                        {!images ? (
                            <>
                                <div className='textUpload'>
                                    <CloudUploadIcon color='#1890FF' fontSize='large'/>
                                    <label  for="file"><strong className='Modif'>{t("profilUser.Cliquez ou deposer")}</strong><span className='notSoClear'> {t("profilUser.image dans cette zone")}</span>.</label>
                                    <p className='notSoClear'>SVG, PNG, JPG or GIF (max, 800 X 800px)</p>
                                </div>
                                <FileBase64 accept="image/*" className="boxDragndrop" type="file" multiple={false} onDone={( e ) => {onChange(e);setImages(e.base64)}} />
                            </>
                        ):(
                            <><img className='imagePicked' src={images} alt="The Image That You Picked" />
                            <HighlightOffIcon onClick={()=>setImages()} fontSize='large' className='cancelUploadingThis'/></>
                        )}
                    </div>
                </div>
            </div>
            <div className='card2'>
                <div className='ProfileCardTitle'>{t("profilUser.Quotas et facturation")}</div>
                <hr className='underLineHeaderProfile'/>
                <div className='ProfileStatsAndPurchase'>
                    <div className='circlePath'>
                        <CircularProgressbar
                            className='StatCircle'
                            value={progress}
                            text={`${progress}%`}
                            styles={buildStyles({
                            pathColor: '#1890FF',
                            textColor: '#1890FF',
                            textSize: 18,
                            trailColor: '#f2f2f2',
                            })}
                        />
                        <p className='notSoClear'>600Mo/1Go</p>
                    </div>
                    <div className='circlePath'>
                        <p>{t("profilUser.Vous avez utilisé")} "60 %” {t("profilUser.du pack Obtenez plus d'espace")} </p>
                        <button className='buttonToPurchase'>{t("profilUser.METTRE A JOUR")}</button>
                    </div>
                </div>
                <hr className='underLineHeaderProfile'/>
            </div>
        </div>
        <div className='card3'>
            <div className='ProfileCardTitle'>{t("profilUser.Information Personnelle")}</div>
            <hr className='underLineHeaderProfile'/>
            <div className='dataChangeProfile'>
                <div className='firstPartProfileInputs'>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Nom">{t("profilUser.Nom")} <span className='requiredStar'>*</span></label>
                        <TextField
                            error={!valid.LastName && (newUserData?.LastName?.length > 0)}
                            helperText={!valid.LastName && (newUserData?.LastName?.length > 0) ? t("this input should contain letters") : ""}
                            id='Nom'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.LastName}
                            onChange={(e)=>onChangeUserProfile("LastName",e.target.value)}
                            InputProps={{
                                startAdornment: (
                                <PersonOutlineOutlinedIcon sx={{ color: 'gray',marginRight:"10px"  }} />
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Prénom">{t("profilUser.Prénom")} <span className='requiredStar'>*</span></label>
                        <TextField
                            error={!valid.FirstName && (newUserData?.FirstName?.length > 0)}
                            helperText={!valid.FirstName && (newUserData?.FirstName?.length > 0) ? t("this input should contain letters") : ""}
                            id='Prénom'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.FirstName}
                            onChange={(e)=>onChangeUserProfile("FirstName",e.target.value)}
                            InputProps={{
                                startAdornment: (
                                <PersonOutlineOutlinedIcon sx={{ color: 'gray',marginRight:"10px" }} />
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Email">{t("profilUser.Email")} <span className='requiredStar'>*</span></label>
                        <TextField
                            error={!valid.Email && (newUserData?.Email?.length > 0)}
                            helperText={!valid.Email && (newUserData?.Email?.length > 0) ? t("This mail is not valid") : ""}
                            id='Email'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.Email}
                            onChange={(e)=>onChangeUserProfile("Email",e.target.value)}
                            InputProps={{
                                startAdornment: (
                                <EmailOutlinedIcon sx={{ color: 'gray',marginRight:"10px" }} />
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Téléphone">{t("profilUser.Téléphone")} <span className='requiredStar'>*</span></label>
                        <TextField
                            error={!valid.Phone && (newUserData?.Phone?.length > 0)}
                            helperText={!valid.Phone && (newUserData?.Phone?.length > 0) ? t("this input should contain only numbers") : ""}
                            id='Téléphone'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.Phone}
                            onChange={(e)=>onChangeUserProfile("Phone",e.target.value)}
                            InputProps={{
                                startAdornment: (
                                <LocalPhoneIcon sx={{ color: 'gray',marginRight:"10px"}} />
                                ),
                            }}
                        />
                    </FormControl>
                </div>
                    <FormControl sx={{ width:"100%", position:"relative" , marginBottom:"1rem"}}>
                        <label className='theGapForSpecialTitles' htmlFor="Addresse">{t("profilUser.Addresse")} <span className='requiredStar'>*</span></label>
                        <TextField
                            error={!valid.Adresse && (newUserData?.Adresse?.length > 0)}
                            helperText={!valid.Adresse && (newUserData?.Adresse?.length > 0) ? t("this input should contain letters") : ""}
                            id='Addresse'
                            multiline
                            minRows={4}
                            value={newUserData?.Adresse}
                            onChange={(e)=>onChangeUserProfile("Adresse",e.target.value)}
                            sx={{color: '#E0E0E0' , borderRadius:"12px"}}
                            InputProps={{
                                startAdornment: (
                                <DriveFileRenameOutlineOutlinedIcon sx={{ color: 'gray',marginRight:"10px",marginBottom: "auto" }} />
                                ),
                            }}
                        />
                    </FormControl>
                <div className='firstPartProfileInputs'>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Ville">{t("profilUser.Ville")}</label>
                        <TextField
                            error={!valid.City && (newUserData?.City?.length > 0)}
                            helperText={!valid.City && (newUserData?.City?.length > 0) ? t("this input should contain letters") : ""}
                            id='Ville'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.City}
                        onChange={(e)=>onChangeUserProfile("City",e.target.value)}/>
                        
                    </FormControl>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Code_Postal">{t("profilUser.Code Postal")}</label>
                        <TextField
                            error={!valid.PostalCode && (newUserData?.PostalCode?.length > 0)}
                            helperText={!valid.PostalCode && (newUserData?.PostalCode?.length > 0) ? t("this input should contain only numbers") : ""}
                            id='Code_Postal'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.PostalCode}
                        onChange={(e)=>onChangeUserProfile("PostalCode",e.target.value)}/>
                        
                    </FormControl>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Société">{t("profilUser.Société")}</label>
                        <TextField
                            error={!valid.Society && (newUserData?.Society?.length > 0)}
                            helperText={!valid.Society && (newUserData?.Society?.length > 0) ? t("this input should contain letters") : ""}
                            id='Société'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.Society}
                        onChange={(e)=>onChangeUserProfile("Society",e.target.value)}/>
                        
                    </FormControl>
                    <FormControl>
                        <label className='theGapForSpecialTitles' htmlFor="Nombre_de_Personnage">{t("profilUser.Nombre de Personnage")}</label>
                        <TextField
                            error={!valid.NumberPerson && (newUserData?.NumberPerson?.length > 0)}
                            helperText={!valid.NumberPerson && (newUserData?.NumberPerson?.length > 0) ? t("this input should contain only numbers") : ""}
                            id='Nombre_de_Personnage'
                            sx={{color: '#E0E0E0',borderRadius:"12px"}}
                            value={newUserData?.NumberPerson}
                        onChange={(e)=>onChangeUserProfile("NumberPerson",e.target.value)}/>
                        
                    </FormControl>
                </div>
                <div className='ProfileActionsButtons'>
                    <Button className={`${(show && checkVlidationInputs()) ? "" : "dontUpdate"}`} onClick={()=>updateUserDataProfileChange(newUserData)} sx={{height:"2.5rem" , fontWeight:"600",fontSize:"14px" , letterSpacing:"0.4px" , lineHeight:"24px" }} variant="contained">{t("Profile.register")}</Button>
                    <Button onClick={()=>onReset()} sx={{height:"2.5rem" , fontWeight:"600",fontSize:"14px" , letterSpacing:"0.4px" , lineHeight:"24px" }} variant="text">{t("Profile.cancel")}</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profile