import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setLayoutDashboardPages } from "../store/layoutActions";
import { setAppSetLogout,setConnexionConstraintDataOnchange } from "../../connexion/store/connexionActions";
import {
  // SEGMENTURL,
  OPTIONS,
  // PHOTPRISM_API,
  // SVGMATORAPIurl,
  LIVE_URL,
  STAT_URL,
} from "./conf";
import {
  // setSegmentList,
  // setCryptText,
  // setPhotoPrismUrl,
  // setSVGmatorUrl,
  setLiveUrl,
  setStatVideoUrl,
  setStatAuditUrl,
  setStatLiveUrl,
  setStatExportUrl,
  setStatDashboardUrl
  // setVideo,
} from "../store/layoutActions";
import { GlobalHooks } from "../../utils/Hooks";
import {GetDataPermissions} from "../../APIcalls"
import {setshowDivsConditionsPermission,setIsAdmin} from "../../connexion/store/connexionActions"
import eventHub from "../../eventHub";
export const Hooks = () => {
  const [lang, setLang] = useState<boolean>(true);
  const {removeCookie,setCookie} = GlobalHooks() 
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === "IFRAME";
  // let iframe = document.getElementById("profile-iframe");
  // const getUrlCryptTextSegment = async () => {
  //   const response = await fetch(SEGMENTURL, OPTIONS)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       dispatch(
  //         setCryptText(data?.url_display_segment?.split("/")[4].split("?")[0])
  //       );
  //       getSVGmatorUrl(data?.url_display_segment?.split("/")[4].split("?")[0]);
  //       dispatch(setSegmentList(data));
  //     });
  // };

  window.addEventListener("message", function (event: any) {
    initHelp(event.data);
  });
  window.addEventListener("onmessage", function (event: any) {
    initHelp(event.data);
  });

  function initHelp(type: any) {
    if (typeof type !== undefined) {
      if (type.info === "addVideo") {
        history.push("/medias/videos/upload");
      }
      if (
        type.info === "editVideo" ||
        type.info === "sousTitragesVideo" ||
        type.info === "richMediaVideo" ||
        type.info === "montageVideo"
      ) {
        history.push("/medias/videos/edit");
      }
    }
  }
  const getURLLive = async (op:any) => {
    await fetch(LIVE_URL, op)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setLiveUrl(data?.url));
      });
  };
  const getURLSTATDashboard = async (op:any) => {
    await fetch(STAT_URL, op)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setStatDashboardUrl(data?.url));
      });
  };

  const getURLSTATVideo = async (op:any) => {
    await fetch(STAT_URL, op)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setStatVideoUrl(data?.url));
      });
  };
  const getURLSTATAudit = async (op:any) => {
    await fetch(STAT_URL, op)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setStatAuditUrl(data?.url));
      });
  };
  const getURLSTATLive = async (op:any) => {
    await fetch(STAT_URL, op)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setStatLiveUrl(data?.url));
      });
  };
  const getURLSTATExport = async (op:any) => {
    await fetch(STAT_URL, op)
      .then((response) => response.json())
      .then((data) => {
        dispatch(setStatExportUrl(data?.url));
      });
  };


  // setStatVideoUrl,
  // setStatAuditUrl,
  // setStatLiveUrl,
  // setStatExportUrl,
  // setStatDashboardUrl






//STAT_URL
  // const getURLVideo = async () => {
  //   await fetch(VIDEO_URL, OPTIONS)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       dispatch(setVideo(data?.url));
  //     });
  // };
  // const getPhotoSVGUrls = async () => {
  //   await fetch(PHOTPRISM_API, OPTIONS)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       dispatch(setPhotoPrismUrl(data?.url));
  //     });
  // };

  // const getSVGmatorUrl = async (cryptext: any) => {
  //   await fetch(SVGMATORAPIurl + `?cryptext=${cryptext}`, OPTIONS)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       verifyUrlbeginwithhttps(data?.display_default_urls?.svg_mator)
  //         ? dispatch(
  //             setSVGmatorUrl(data?.display_default_urls?.svg_mator.slice(2))
  //           )
  //         : dispatch(setSVGmatorUrl(data?.display_default_urls?.svg_mator));
  //     });
  // };
  // const verifyUrlbeginwithhttps = (url: any) => {
  //   return url.startsWith("//");
  // };
  //******************** fct SiderMenu ****************//
  

  //****************************** fct LayoutBanner *******************************//
  const handleLanguageMenuClick = (e: any) => {
    if (e.key === "FR") {
      setLang(true);
      i18n.changeLanguage("fr");
      dispatch(
        setLayoutDashboardPages({
          layoutConstraintsDataNameChange: "lang",
          layoutConstraintsDataValueChange: "fr",
        })
      );
    } else {
      setLang(false);
      i18n.changeLanguage("en");
      dispatch(
        setLayoutDashboardPages({
          layoutConstraintsDataNameChange: "lang",
          layoutConstraintsDataValueChange: "en",
        })
      );
    }
  };

  const handleSettingMenuClick = () => {};
  const handleLogout = () => {
    removeCookie("authToken",{path:"/"})
    removeCookie("thumbnail",{path:"/"})
    removeCookie("validated",{path:"/"})
    removeCookie("username",{path:"/"})
    removeCookie("redic",{path:"/"})
    removeCookie("id",{path:"/"})
    dispatch(setConnexionConstraintDataOnchange({
      constraintDataNameChange:"message",
      constraintDataValueChange:""
  }))
  dispatch(setConnexionConstraintDataOnchange({
    constraintDataNameChange: "secondFace",
    constraintDataValueChange: false 
}));
    history.push("/");
    dispatch(setAppSetLogout(""));
    window.location.reload()
  };
  eventHub.on("disconnect",async(data:any)=>{
    handleLogout()
  })

  const showDrawer = () => {
    dispatch(
      setLayoutDashboardPages({
        layoutConstraintsDataNameChange: "visible",
        layoutConstraintsDataValueChange: true,
      })
    );
  };
  const onClose = () => {
    dispatch(
      setLayoutDashboardPages({
        layoutConstraintsDataNameChange: "visible",
        layoutConstraintsDataValueChange: false,
      })
    );
  };

  // const getCryptextLives = async () =>{
  //   await GetData("https://k8s-scaleway-test-api-v4.webtv-solution.com/lives/video/get-url-list-live?type=list")
  //     .then(res => {

  //     })
  // }
   const getModules = async (e:any) => {
              await GetDataPermissions("/core/rights/get-user-rights",e)
                .then((res)=>{
                    dispatch(
                        setshowDivsConditionsPermission(res.data)
                    );
                    dispatch(
                        setIsAdmin(res.data.userData.roles.indexOf("ROLE_SUPER_ADMIN") !== -1)
                    )
              }).catch(err=>{console.error(err)})
   }

  return {
    getModules,
    handleLanguageMenuClick,
    getURLSTATVideo,
    getURLSTATAudit,
    getURLSTATLive,
    getURLSTATExport,
    // getUrlCryptTextSegment,
    // getPhotoSVGUrls,
    // getURLVideo,
    getURLSTATDashboard,
    getURLLive,
    lang,
    setLang,
    handleSettingMenuClick,
    handleLogout,
    showDrawer,
    onClose,
  };
};
