import React, {Suspense} from 'react';
import App from './App';
import ApolloClient from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import {ApolloProvider} from '@apollo/react-hooks';
import {setContext} from 'apollo-link-context';
import {Provider} from "react-redux";
import store from "./redux/store";
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import './i18n';
import {Spin} from "antd";
import {BrowserRouter as Router} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import Cookies from 'js-cookie';

const httpLink = createUploadLink({ uri: window.process.env.API_HOST_DAM+"/query" });
  
  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
    }
  
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        if (error.extensions && error.extensions.code === 'UNAUTHENTICATED') {
        }
      }
    }
  });
  
  const authLink = setContext((_, { headers }) => {
    const token = Cookies.get('authToken');
  
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });


export default (
    <Suspense fallback={(<Spin className="Spin">
        <div></div>
    </Spin>)}>
        <Provider store={store}>
            <ApolloProvider client={client}>
                    <Router>
                        <CookiesProvider>
                            <App/>
                         </CookiesProvider>
                    </Router>
            </ApolloProvider>
        </Provider>
    </Suspense>
);