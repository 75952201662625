import React from "react";
import "./DashboardPart.css"
import { GlobalHooks } from "../../../../utils/Hooks";
import { useTranslation } from "react-i18next";

const DashboardPart = () => {
  const {cookies,setCookie} = GlobalHooks()
  const colorValue = cookies.ColorTheme === "default" ? "#1976D2" : cookies.ColorTheme === "c2" ? "#002d3d" : cookies.ColorTheme === "c3" ? "#b4c7e5" : cookies.ColorTheme === "c4" ? "#ffcd38" : cookies.ColorTheme === "c5" ? "#8ee5dc"  : "#F32B84" 
  const {t} = useTranslation()
  return (
    <div className="dashboardOptionsBorder">
      <div className='Blue-Title' style={{color:colorValue}}>{t("OptionAffichage.Dashboard")}</div>
      <h4 className="titlesDashboard">{t("OptionAffichage.Choisir le mode d'affichage de la liste des vidéos")}</h4>
      <div>
        <label >
          <input style={{accentColor:colorValue}} checked={cookies.ModeAffichage === "grand"} onChange={e=>setCookie("ModeAffichage",e.target.value,{path:"/"})} className="radio-options-profile" type="radio" name="grand" value="grand" />
          {t("OptionAffichage.Liste grande taille")}
        </label>
        <br />
        <label >
          <input style={{accentColor:colorValue}} checked={cookies.ModeAffichage === "moyenne"} onChange={e=>setCookie("ModeAffichage",e.target.value,{path:"/"})} className="radio-options-profile" type="radio" name="moyenne" value="moyenne" />
          {t("OptionAffichage.Liste moyenne taille")}
        </label>
        <br />
        <label >
          <input style={{accentColor:colorValue}} checked={cookies.ModeAffichage === "vignette"} onChange={e=>setCookie("ModeAffichage",e.target.value,{path:"/"})} className="radio-options-profile" type="radio" name="vignette" value="vignette" />
          {t("OptionAffichage.Vignettes")}
        </label>
        <br />
        <label >
          <input style={{accentColor:colorValue}} checked={cookies.ModeAffichage === "petite"} onChange={e=>setCookie("ModeAffichage",e.target.value,{path:"/"})} className="radio-options-profile" type="radio" name="petite" value="petite" />
          {t("OptionAffichage.Liste petite taille")}
        </label>
        <br />
        <label >
          <input style={{accentColor:colorValue}} checked={cookies.ModeAffichage === "repertoir"} onChange={e=>setCookie("ModeAffichage",e.target.value,{path:"/"})} className="radio-options-profile" type="radio" name="repertoir" value="repertoir" />
          {t("OptionAffichage.Répertoires")}
        </label>
        <br />
      </div>
      <h4 className="titlesDashboard">
        {t("OptionAffichage.Choisir le nombre des éléments à afficher dans la liste des vidéos")}
      </h4>
      <div className="grid-systheme-for-radios-options-table-length">
        <label>
          <input style={{accentColor:colorValue}} checked={cookies.GridTaille === "5"} onChange={e=>setCookie("GridTaille",e.target.value,{path:"/"})} className="space-for-radios-table-length" type="radio" name="option-5" value="5" />
          5
        </label>
        <label>
          <input style={{accentColor:colorValue}} checked={cookies.GridTaille === "10"} onChange={e=>setCookie("GridTaille",e.target.value,{path:"/"})}  className="space-for-radios-table-length" type="radio" name="option-10" value="10" />
          10
        </label>
        <label>
          <input style={{accentColor:colorValue}} checked={cookies.GridTaille === "20"} onChange={e=>setCookie("GridTaille",e.target.value,{path:"/"})} className="space-for-radios-table-length" type="radio" name="option-20" value="20" />
          20
        </label>
        <label>
          <input style={{accentColor:colorValue}} checked={cookies.GridTaille === "30"} onChange={e=>setCookie("GridTaille",e.target.value,{path:"/"})} className="space-for-radios-table-length" type="radio" name="option-30" value="30" />
          30
        </label>
        <label>
          <input style={{accentColor:colorValue}} checked={cookies.GridTaille === "50"} onChange={e=>setCookie("GridTaille",e.target.value,{path:"/"})} className="space-for-radios-table-length" type="radio" name="option-50" value="50" />
          50
        </label>
        <label>
          <input style={{accentColor:colorValue}} checked={cookies.GridTaille === "100"} onChange={e=>setCookie("GridTaille",e.target.value,{path:"/"})} className="space-for-radios-table-length" type="radio" name="option-100" value="100" />
          100
        </label>
      </div>
      <h4 className="titlesDashboard">{t("OptionAffichage.Choisir les parties à afficher dans le tableau de bord")}</h4>
      <div className="checkboxes-options-affichage">
        <label>
          <input style={{accentColor:colorValue}} className="checkboxes-right-space-options" type="checkbox" name="option-2-1" value="7" />
          {t("OptionAffichage.Dernières vidéos ajoutées")}
        </label>
        <label>
          <input style={{accentColor:colorValue}} className="checkboxes-right-space-options" type="checkbox" name="option-2-2" value="8" />
          {t("OptionAffichage.Les vidéos les plus visitées")}
        </label>
        <label>
          <input style={{accentColor:colorValue}} className="checkboxes-right-space-options" type="checkbox" name="option-2-3" value="9" />
          {t("OptionAffichage.Statistiques audience")}
        </label>
        <label>
          <input style={{accentColor:colorValue}} className="checkboxes-right-space-options" type="checkbox" name="option-2-4" value="10" />
          {t("OptionAffichage.Statistiques nombre des vues par durée de visionnage")}
        </label>
      </div>
    </div>
  );
};

export default DashboardPart;
