import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import AddIcon from '@mui/icons-material/Add';
import VideocamIcon from '@mui/icons-material/Videocam';
import filtre_bare_live from "../assets/filter_bare_live.png"
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';

import InfoIcon from '@mui/icons-material/Info';

import EmailIcon from '@mui/icons-material/Email';
import BarChartIcon from '@mui/icons-material/BarChart';
import CastIcon from '@mui/icons-material/Cast';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
const LivesBookGuide = () => {
  const {t} = useTranslation()
  const divStyle = {}
  const iconsStyle = {display:"inline-flex",alignItems:"center",justifyContent:"center",marginInline:"1rem",borderRadius:"4px",height:"2rem",width:"100px",color:Cookies.get('ColorTheme') === "default" ? "#F8FBFF" : Cookies.get('ColorTheme') === "c2" ? "#cee3e7" : Cookies.get('ColorTheme') === "c3" ? "#426c7a" : Cookies.get('ColorTheme') === "c4" ? "#8499e4" : Cookies.get('ColorTheme') === "c5" ? "#11776c" : "#aaaaaa" ,backgroundColor : Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"}
  const color = Cookies.get('ColorTheme') === "default" ? "#1976D2" : Cookies.get('ColorTheme') === "c2" ? "#002d3d" : Cookies.get('ColorTheme') === "c3" ? "#b4c7e5" : Cookies.get('ColorTheme') === "c4" ? "#ffcd38" : Cookies.get('ColorTheme') === "c5" ? "#8ee5dc"  : "#F32B84"
  return (
    <>
      <div style={divStyle}>{t("Books.add_button_live_help")}<span style={iconsStyle}><AddIcon fontSize='1rem'/>{t("add")}</span></div> 
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.In the top left, the button")}<span style={iconsStyle}><VideocamIcon fontSize='1rem'/>{t("Books.DIFFUSION")}</span>{t("Books.allows you to view list of lives in 5 modes")}</div> 
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.arrow_button_live_help1")}<span style={iconsStyle}>{t("Books.next")}</span>{t("Books.arrow_button_live_help2")}<span style={iconsStyle}>{t("Books.prev")}</span></div> 
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.Use the functions at the top of the screen to perform multiCriteria searches")}</div> 
      <div>
        <img style={{maxWidth:"100%"}} src={filtre_bare_live} alt="" />
      </div>
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.If you notice, there are arrows")}<span style={{...iconsStyle,width:"40px"}}><UnfoldMoreIcon fontSize='1rem'/></span>{t("Books.to the right of each column, although it's arrows are here to help you sort the list")}</div> 
      <hr style={{transform:"scaleY(0.5)"}} />
      <div style={divStyle}>{t("Books.In the Action column, several actions can be applied to live")}</div> 
      <div className='ActionsBooksMediaVideo'>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><EditIcon fontSize='1rem'/></span>{" "}{t("Books.Edit a live")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><RemoveRedEyeIcon fontSize='1rem'/></span>{" "}{t("Books.Live detail")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Encoding with Pearl")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Export links of a live")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><InfoIcon fontSize='1rem'/></span>{" "}{t("Books.Live data")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><ChatBubbleIcon fontSize='1rem'/></span>{" "}{t("Books.View list comments of a live")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><EmailIcon fontSize='1rem'/></span>{" "}{t("Books.Send invitations")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><BarChartIcon fontSize='1rem'/></span>{" "}{t("Books.Live statistics")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><CastIcon fontSize='1rem'/></span>{" "}{t("Books.Add a screen share for live")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><DeleteIcon fontSize='1rem'/></span>{" "}{t("Books.Delete a live")}</div>
            <div className="oneActionBook"><span style={{...iconsStyle,background:"none",color:color , width:"20px",marginInline:0}}><VideocamIcon fontSize='1rem'/></span>{" "}{t("Books.Broadcasting a live")}</div>
        </div>
    </>
  )
}

export default LivesBookGuide