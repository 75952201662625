import React from 'react';
import Box from '@mui/material/Box';
import Tabs ,{tabsClasses} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { GlobalHooks } from '../../../utils/Hooks';
const TabBar = ( {SubMenuData} ) => {
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {cookies} = GlobalHooks()
  const {t} = useTranslation()
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        width:"100%",
        height:"50px",
        bgcolor: cookies.ColorTheme === "default" ? "#F8FBFF" : cookies.ColorTheme === "c2" ? "#cee3e7" : cookies.ColorTheme === "c3" ? "#426c7a" : cookies.ColorTheme === "c4" ? "#8499e4" : cookies.ColorTheme === "c5" ? "#11776c" : "#aaaaaa",
      }}
    >
       <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="visible arrows tabs example"
        sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          
          }}
      >
        {SubMenuData && SubMenuData.map((tab, index) => (
         <Tab 
            sx={{display:'flex',flexDirection:"row",alignItems:"center",justifyContent:"center" ,padding:"5px 16px",}}
             key={index}
             onClick={(e)=>{if(tab.indicatorSolutionOnly !== "/abonnement"){
                if(!(tab.MenuItem.length > 0)){history.push(tab.indicatorSolutionOnly)}
            };handleMenuOpen(e)}}
             label={
                
               <React.Fragment>
                 <IconButton
                   size="small"
                   aria-label="menu"
                   edge="start"
                 >
                   <i className={tab.iconClass}></i>
                 </IconButton>
                 {t(tab.title)}
               </React.Fragment>
             }
           />
         ))}
       </Tabs> 

            {SubMenuData[value]?.MenuItem.length >= 1 && (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    
                    }}
                >
                    { SubMenuData[value]?.MenuItem?.map((child, index) => (
                    <MenuItem key={index} onClick={()=>{handleMenuClose();if(!(child.MenuItem.length > 0) && SubMenuData[value]?.indicatorSolutionOnly !== "/abonnement"){history.push(child.indicatorSolutionOnly)}}}>
                        <React.Fragment>
                            <IconButton
                            size="small"
                            aria-label="menu"
                            edge="start"
                            >
                            <i className={child.iconClass}></i>
                            </IconButton>
                            {t(child.title)}
                            {SubMenuData[value]?.indicatorSolutionOnly === "/abonnement" && (
                                <DoNotDisturbIcon sx={{marginLeft:"0.7rem", color: "black" }} />
                            )}
                        </React.Fragment>
                    </MenuItem>
                    ))}
                </Menu>
            )}
    </Box>
  );
};

export default TabBar;