import AbonnementGroupBookGuide from "../IntegratedAbonnement/AbonnementGroupBookGuide";
import AbonnementUserBookGuide from "../IntegratedAbonnement/AbonnementUserBookGuide";
import ChutierBookGuide from "../IntegratedMedia.jsx/ChutierBookGuide";
import EvenementBookGuide from "../IntegratedMedia.jsx/EvenementBookGuide";
import LivesBookGuide from "../IntegratedMedia.jsx/LivesBookGuide";
import PlayListBookGuide from "../IntegratedMedia.jsx/PlayListBookGuide";
import PodcastBookGuide from "../IntegratedMedia.jsx/PodcastBookGuide";
import VideoBookGuide from "../IntegratedMedia.jsx/VideoBookGuide";
import ProfileGroupBookGuide from "../IntegratedProfile/ProfileGroupBookGuide";
import ProfileIpBookGuide from "../IntegratedProfile/ProfileIpBookGuide";
import ProfileUserBookGuide from "../IntegratedProfile/ProfileUserBookGuide";
import ThemeBookGuide from "../IntegratedClassment/ThemeBookGuide";
import CanalBookGuide from "../IntegratedClassment/CanalBookGuide";
import ConfigurationServerBookGuide from "../IntegratedConfiguration/ServeurBookGuide";
import ClientBookGuide from "../IntegratedConfiguration/ClientBookGuide";
import PlayerParametreBookGuide from "../IntegratedConfiguration/PlayerParametresBookGuide";
const books = [
    // {
    //     path: '/display_new/new_template_manager',
    //     component: ProgrammeNewIntegrated,
    //     key: '/display_new/new_template_manager',
    // },
    // {
    //     path: '/display_new/new_template_segment',
    //     component: SegmentIntegrated,
    //     key: '/display_new/new_template_segment',
    // },
    // {
    //     path: '/display_new/schedule',
    //     component: GrilleDesProgrammesNewIntegrated,
    //     key: '/display_new/schedule',
    // },
    // {
    //     path: '/display/template_manager',
    //     component: ProgrammeIntegrated,
    //     key: '/display/template_manager',
    // },
    // {
    //     path: '/display/schedule',
    //     component: GrilleDesProgrammesIntegrated,
    //     key: '/display/schedule',
    // },
    // {
    //     path: '/stats/dashboard',
    //     component: DashboardStatsIntegrated,
    //     key: '/stats/dashboard',
    // },
    // {
    //     path: '/stats/acces',
    //     component: StatsAuditIntegrated,
    //     key: '/stats/acces',
    // },
    // {
    //     path: '/stats/consumptions',
    //     component: StatsConsommationIntegrated,
    //     key: '/stats/consumptions',
    // },
    // {
    //     path: '/stats/mooc',
    //     component: StatsDigitalIntegrated,
    //     key: '/stats/mooc',
    // },
    // {
    //     path: '/stats/live',
    //     component: StatsDirectIntegrated,
    //     key: '/stats/live',
    // },
    // {
    //     path: '/stats/list_videos',
    //     component: StatsExportsIntegrated,
    //     key: '/stats/list_videos',
    // },
    // {
    //     path: '/stats/stockage',
    //     component: StatsStockageIntegrated,
    //     key: '/stats/stockage',
    // },
    // {
    //     path: '/stats/vod',
    //     component: StatsVideosIntegrated,
    //     key: '/stats/vod',
    // },
    // {
    //     path: '/specifics/direction',
    //     component: DirectionIntegrated,
    //     key: '/specifics/direction',
    // },
    // {
    //     path: '/specifics/iptv',
    //     component: IpTvsIntegrated,
    //     key: '/specifics/iptv',
    // },
    // {
    //     path: '/specifics/station',
    //     component: StationIntegrated,
    //     key: '/specifics/station',
    // },
    // {
    //     path: '/boitier/rmboxgroup',
    //     component: GroupRMBoxIntegrated,
    //     key: '/boitier/rmboxgroup',
    // },
    // {
    //     path: '/boitier/rmbox',
    //     component: RMBoxIntegrated,
    //     key: '/boitier/rmbox',
    // },
    // {
    //     path: '/boitier/zone',
    //     component: ZoneIntegrated,
    //     key: '/boitier/zone',
    // },
    // {
    //     path: '/boitier/autoriseip',
    //     component: IpAuthIntegrated,
    //     key: '/boitier/autoriseip',
    // },
    // {
    //     path: '/configuration/home/conf',
    //     component: HomeConfigurationIntegrated,
    //     key: '/configuration_accueil_page',
    // },
    // {
    //     path: '/configuration/home/list',
    //     component: HomeAcceuilleIntegrated,
    //     key: '/video_accueil_page',
    // },
    // {
    //     path: '/configuration/home/push',
    //     component: HomeVideoPushIntegrated,
    //     key: '/push_accueil_page',
    // },
    // {
    //     path: '/configuration/metaData',
    //     component: MetadonnesIntegrated,
    //     key: '/metadata_configuration',
    // },
    // {
    //     path: '/configuration/configuration_mooc',
    //     component: MoocIntegrated,
    //     key: '/mooc_configuration',
    // },
    {
        path: '/configuration/server',
        component: ConfigurationServerBookGuide,
        title: 'welcome_Server',
    },
    // {
    //     path: '/configuration/presets',
    //     component: PresetIntegrated,
    //     key: '/preset_configuration',
    // },
    // {
    //     path: '/configuration/referrers',
    //     component: ReferentIntegrated,
    //     key: '/ref_configuration',
    // },
    {
        path: '/configuration/client',
        component: ClientBookGuide,
        title: 'welcome_Client',
    },
    // {
    //     path: '/configuration/player_parameters',
    //     component: PlayerParametreBookGuide,
    //     title: 'welcome_Player',
    // },
    // {
    //     path: '/configuration/news',
    //     component: ActualityIntegrated,
    //     key: '/actuality_configuration',
    // },
    {
        path: '/media/lives',
        component: LivesBookGuide,
        title: 'welcome_live',
    },
    {
        path: '/media/video',
        component: VideoBookGuide,
        title: 'welcome_video',
    },
    // {
    //     path: '/media/podcast',
    //     component: PodcastBookGuide,
    //     title: '/podcast',
    // },
    {
        path: '/media/playlist',
        component: PlayListBookGuide,
        title: 'welcome_Playlist',
    },
    // {
    //     path: '/media/chutier',
    //     component: ChutierBookGuide,
    //     title: '/chutier',
    // },
    {
        path: '/media/event',
        component: EvenementBookGuide,
        title: 'welcome_Event',
    },
    // {
    //     path: '/interactivity/sondage',
    //     component: SondageIntegrated,
    //     title: '/sondage',
    // },
    // {
    //     path: '/configuration/display_options',
    //     component: OptionsProfile,
    //     title: '/OptionDaffichage',
    // },
    // {
    //     path: '/support/Api/title',
    //     component: Apititle,
    //     title: '/Apititle',
    // },
    // {
    //     path: '/',
    //     component: DashboardIntegrated,
    //     title: '/',

    // },
    // {
    //     path: '/dashboard',
    //     component: DashboardIntegrated,
    //     title: '/dashboard',

    // },
    {
        path: '/abonnement/group',
        component: AbonnementGroupBookGuide,
        title: '/abonnement/group',
    },
    {
        path: '/abonnement/user',
        component: AbonnementUserBookGuide,
        title: '/abonnement/user',
    },
    {
        path: '/profiles/user',
        component: ProfileUserBookGuide,
        title: 'welcome_user',
    },
    {
        path: '/profiles/group',
        component: ProfileGroupBookGuide,
        title: 'welcome_group',
    },
    {
        path: '/profiles/ip',
        component: ProfileIpBookGuide,
        title: 'welcome_IP',
    },
    {
        path: '/classifying/theme',
        component: ThemeBookGuide,
        title: 'welcome_Theme',
    },
    {
        path: '/classifying/canal',
        component: CanalBookGuide,
        title: 'welcome_Channel',
    },
    // {
    //     path: '/profiles/user/profilconnected',
    //     component: Profile,
    //     key: '/profile',
    // },
]

export default books;
