
//******************** connexion actions***************//

export function setConnexionOnchange(e:any){

    return{
        type: "SET_ConnexionOnchange",
        payload:e,
    }
}

// ************ Connexion Variables ***************** //
export function setAppSetLogin(e:any){
    return{
        type:"SET_AppSetLogin",
        payload:e
    }
}

export function setAppSetLogout(e:any){
    return{
        type:"SET_AppSetLogout",
    }
}

//*******************Constraint Data actions*******************//

export function setConnexionConstraintDataOnchange(e:any){
    return{
        type:"SET_ConnexionConstraintDataOnchange",
        payload:e
    }
}

export function setshowDivsConditionsPermission(e:any){
    return {
        type:"SET_Permissions",
        payload:e
    }
}

export function setIsAdmin(e:any){
    return {
        type:"SET_isAdmin",
        payload:e
    }
}

