import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Style.less";
import RoutingList from "../router/RoutingList";
import routes from "../router/routes";
import { useHistory } from "react-router-dom";
import Error from "./components/Error";
import Navbar from "./components/NavBar/NavBar";
import Sidebar from "./components/SideBar/SideBar";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import Content from "./components/Content";
import {useWindowSize} from "./utils/resize"
import { GlobalHooks } from "../utils/Hooks";
import TabBar from "./components/TabBarLayout/TabBar";
import SubMenuData from "./utils/DataMenu"
import { Hooks } from "./utils/hooks";
import Cookies from "js-cookie";
import BooksSwitcher from "../router/BooksSwitcher";
import { useTranslation } from "react-i18next";
function MainLayout() {
  const {t} = useTranslation()
  const {getModules} = Hooks()
  const {cookies} = GlobalHooks()
  const windowSize = useWindowSize()
  const [open, setOpen] = useState<boolean>(true);
  const history = useHistory();
  const routesPath = routes.map((item) => item.path);
  const { search } = useLocation();
  const obj = new URLSearchParams(search);
  const [openTheBook,setOpenTheBook] = useState(false)

  const handleOnCollapse = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(()=>{
      setOpen(!(windowSize<960))
  },[windowSize])

  useEffect(() => {
    const lang = obj.get("lang");
    if (lang === "en" || lang === "fr") {
      i18next.changeLanguage(lang);
    }
  }, [obj.get("lang")]);

  useEffect(()=>{
    if(Cookies.get("authToken")){
      getModules(Cookies.get("authToken"))
    }
  },[Cookies.get("authToken")])
  useEffect(()=>{
    setOpenTheBook(false)
  },[window.location.pathname])
  return (
    <div className={`globalDashboard ${Cookies.get("MiseEnPage") === "boxed" ? "boxed" : ""}`}>
      {routesPath.includes(history.location.pathname) ? (
        <>
        {cookies.LayoutTheme !== "L4" ? (
          <Sidebar handleOnCollapse={handleOnCollapse} open={open} />
          ) : (<></>)}
          <div style={{width: "100%" , marginLeft: (cookies.LayoutTheme !== "L3" && cookies.LayoutTheme !== "L4" && cookies.LayoutTheme !== "L2") ? open ? 250 : 90 : ""}} className="fillable">
          {cookies.LayoutTheme !== "L4" ? (
            <Navbar setOpenTheBook={setOpenTheBook} handleOnCollapse={handleOnCollapse} open={open} />
          ) : (<></>)}
          {cookies.LayoutTheme === "L2" && (
            <TabBar SubMenuData={SubMenuData}/>
          )}
            <Content open={open} child={<RoutingList />}/>
          </div>
            {openTheBook ? BooksSwitcher({url:window.location.pathname,setOpenTheBook,t}) : <></>}
        </>
      ) : (
        <Error />
      )}
    </div>
  );
}

export default MainLayout;
